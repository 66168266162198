import DynamicButton from "../../components/dynamicButton/dynamicButton";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const TransactionCancelled = (props) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  useEffect(async () => {
    if (token) {
      const getLoad = {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
      };
      try {
        let res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/withdraw/cancel`,
          getLoad
        );
        await res.json().then((response) => {});
      } catch (e) {}
    } else {
      navigate("/user/transactions");
    }
  }, []);
  const transactionsRedirectHandler = () => {
    navigate("/user/transactions");
  };

  return (
    <>
      <Typography
        component="span"
        sx={{ color: "red", fontSize: 24, fontWeight: "bold" }}
      >
        The transaction was cancelled...
      </Typography>
      <Box>
        <DynamicButton
          title="See Transactions"
          clickHandler={() => transactionsRedirectHandler()}
          pl="20px"
          pr="20px"
          pt="5px"
          pb="5px"
          color={true}
        />
      </Box>
    </>
  );
};

export default TransactionCancelled;
