import { Grid, Typography, Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useParams, useNavigate } from "react-router";
// import { useHistory } from "react-router-dom";

import moment from "moment";
import GeneralText from "../../components/generalText/generalText";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
} from "@mui/material";

import teamSizeImg from "../../../assets/Group 168(1).svg";
import registeredImg from "../../../assets/Mask Group 8.svg";
import bracketImg from "../../../assets/Mask Group 9.svg";
import MiniDetail from "../../components/miniDetail/miniDetail";
import pricePoolImg from "../../../assets/Group 16.svg";
import tourBackImg from "../../../assets/tourback.png";
import MediumTile from "../../components/mediumTile/mediumTile";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import { addResult, getTeams, getTournament, joinTournament } from "./reducers";
import WinningTeamTile from "../../components/winningTeamTile/winningTeamTile";
import JoinTeamModal from "../../components/joinTeamModal/joinTeamModal";
import { dateFormate, timeFormate } from "../../../utils/apiutils";
import { capitalize } from "../../../utils/apisauce";
import classes from "./index.module.scss";
import UploadResultModal from "../../components/uploadResultModal/uploadResultModal";
import PlatformTypeBar from "../../components/platformTypeBar/platformTypeBar";
import Schedule from "./components/Schedule";
import { MatchData } from "./components/match";
import { NotificationManager } from "react-notifications";
import { CountertopsOutlined } from "@mui/icons-material";
import axios, { Axios } from "axios";
import Loader from "../../components/Loader/Loader";
const BASE_URL = process.env.REACT_APP_BASE_URL + "/";
const userId = localStorage.getItem("user_id");

export default function TournamentProfile() {
  const [joinTournamentError, setJoinTournamentError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resultModal, setResultModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [storeValues, setStoreValues] = useState(false);
  const [showRecentTab, setShowRecentTab] = useState(false);

  const [teamTabActive, setTeamTabActive] = useState(true);
  const [recentActivityTabActive, setRecentActivityTabActive] = useState(false);

  const [joinTour, setJoinTour] = useState(false);
  const TypesAfterJoining = ["Teams", "Schedule", "Recent Activity"];
  const TypesBeforeJoining = ["Teams", "Schedule"];
  const [typeFilter, setTypeFilter] = useState("Teams");
  const [checkFlag, setFlag] = useState(true);
  let [scheduleData, setScheduleData] = useState();
  const [scheduleErr, setScheduleErr] = useState("");

  const { tournament, teams } = useSelector(
    (state) => state.userTournamentProfile
  );
  const loading = useSelector((state) => state.userTournamentProfile.loading);
  const BackImg = tournament.tournamentTitleImage
    ? BASE_URL + tournament.tournamentTitleImage
    : tourBackImg;

  const { id } = useParams();

  useEffect(async () => {
    dispatch(getTournament({ id: id }));

    const token = localStorage.getItem("userToken");
    const getLoad = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    let res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/tournament/schedule?tournamentId=${id}`,
      getLoad
    );
    await res
      .json()
      .then((data) => {
        if (data.code == 200 && data?.tournamentScheduleData?.length > 0) {
          setScheduleData(data.tournamentScheduleData);
          setScheduleErr("");
        } else if (data.code === 400) {
          setScheduleErr(data.message);
        }
      })
      .catch((err) => { });
  }, []);

  const handleJoinTour = (values) => {
    setConfirmModalOpen(true);
    setStoreValues(values);
  };

  const [tournamentActivity, setTournamentActivity] = useState("");

  const fetchData = async () => {
    const token = localStorage.getItem("userToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/activity/list?tournamentId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // Handle the response data as needed
      setTournamentActivity(response.data.activities);
    } catch (error) {
      // Handle the error condition
    }
  };

  const handleConfirmYes = async () => {
    dispatch(joinTournament(storeValues));

    // Reset the error state
    setJoinTournamentError(null);

    // Close the confirm modal and set the necessary states
    setConfirmModalOpen(false);
    setJoinTour(false);
    setShowRecentTab(true);

    // Handle the error here, if needed
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  const handleClose = () => {
    setJoinTour(false);
    setResultModal(false);
  };
  const setJoinTourHanlder = () => {
    setJoinTour(true);
    dispatch(getTeams());
  };
  const handleUploadResult = (values) => {
    dispatch(addResult(values));
    setResultModal(false);
  };

  const winningTeam = tournament?.winningTeam
    ? tournament.tournamentTeamRecord?.filter((x) => {
      return tournament?.winningTeam === x.teamId;
    })
    : "";
  const team = tournament?.tournamentTeamRecord?.filter((x) =>
    x.teamLeaderId === userId ? x.teamId : null
  );
  const typeFilterHandler = (val) => {
    setTypeFilter(val);
    setTeamTabActive(val === "Teams");
    setRecentActivityTabActive(val === "Recent Activity");
    if (val === "Schedule" && scheduleErr) {
      NotificationManager.error(scheduleErr);
    }
  };
  ///changing time format for recent activity tab
  function getRelativetime(timestamp) {
    return moment(timestamp).format("Y-MM-D h:m:s a");
  }

  useEffect(() => {
    if (tournament.tournamentJoined) {
      setShowRecentTab(true);
      fetchData();
    } else {
      setShowRecentTab(false);
    }
  }, [tournament.tournamentJoined]);
  return (
    <>
      {loading ? (
        <Loader color={1} />
      ) : (
        <>
          {" "}
          <Dialog
            open={confirmModalOpen}
            onClose={handleCloseConfirmModal}
            sx={{
              "& .MuiDialog-paper": {
                width: "438px",
                height: "199px",
              },
            }}
          >
            <DialogTitle
              sx={{
                backgroundColor: "#1A1A1A",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "40px",
                fontSize: "18px",
              }}
            >
              Are you sure you want to join this tournament?
            </DialogTitle>

            <DialogActions
              sx={{
                backgroundColor: "#1A1A1A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "70px",
                gap: "10px",
              }}
            >
              <Button
                onClick={handleCloseConfirmModal}
                sx={{
                  color: "white",
                  bgcolor: "#F26826",
                }}
              >
                No
              </Button>
              <Button
                onClick={handleConfirmYes}
                sx={{
                  color: "white",
                  bgcolor: "#F26826",
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <JoinTeamModal
            id={id}
            teams={teams}
            open={joinTour}
            name={tournament.tournamentName}
            titleImage={BackImg}
            formFieldName={"tournamentId"}
            handleClose={handleClose}
            handleJoinTour={handleJoinTour}
          />
          <UploadResultModal
            id={id}
            scheduleData={scheduleData}
            open={resultModal}
            name={tournament.tournamentName}
            titleImage={BackImg}
            teamId={team?.length ? team[0]?.teamId : null}
            formFieldName={"tournamentId"}
            handleClose={handleClose}
            handleUploadResult={handleUploadResult}
            flag={checkFlag}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid
                item
                md={12}
                className={classes.topSection}
                style={{
                  backgroundImage: `url('${BackImg}')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <IconButton
                  onClick={() => navigate(-1)}
                  className={classes.backChevron}
                >
                  <ChevronLeftIcon sx={{ color: "#F26826", pb: "3px" }} />
                </IconButton>
                <Grid container className={classes.tournamentProfileImg}>
                  <Grid item md={6}>
                    <Box className={classes.gameName}>
                      {dateFormate(tournament.startingDateAndTime)} <br />
                      STARTING AT &nbsp;
                      <span className={classes.timetext}>
                        {timeFormate(tournament.startingDateAndTime)} UTC
                      </span>
                    </Box>
                  </Grid>
                  {!tournament.tournamentJoined ? (
                    <Grid item md={6} className={classes.joinButton}>
                      <PrimaryButton
                        title="Join Tournament"
                        clickHandler={setJoinTourHanlder}
                      />
                    </Grid>
                  ) : (
                    <Grid item md={6} className={classes.joinButton}>
                      <PrimaryButton
                        title="Add Result"
                        clickHandler={() => setResultModal(true)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item md={12} className={classes.centerBar}>
                <Typography component="p" className={classes.tournamentName}>
                  {capitalize(tournament?.tournamentName)}
                </Typography>
                {tournament.description &&
                  <Grid item md={12} className={classes.centerBar}>
                    <Typography component="p" style={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}>
                      Description
                    </Typography>
                    <Typography component="p" style={{ fontSize: "14px", color: "#fff" }}>
                      {tournament?.description}
                    </Typography>
                  </Grid>}
                <Box className={classes.miniDetailsBar}>
                  <Grid container>
                    <Grid item md={12 / 5}>
                      <MiniDetail
                        logo={pricePoolImg}
                        title={"Prize Pool"}
                        value={tournament.prize}
                      />
                    </Grid>
                    <Grid item md={12 / 5}>
                      <MiniDetail
                        logo={teamSizeImg}
                        title={"Team Size"}
                        value={tournament.teamSize}
                      />
                    </Grid>
                    <Grid item md={12 / 5}>
                      <MiniDetail
                        logo={pricePoolImg}
                        title={"Entry Fee"}
                        value={tournament.entryFee}
                      />
                    </Grid>
                    <Grid item md={12 / 5}>
                      <MiniDetail
                        logo={registeredImg}
                        title={"Registered"}
                        value={tournament.registered}
                      />
                    </Grid>
                    <Grid item md={12 / 5}>
                      <MiniDetail
                        logo={bracketImg}
                        title={"Total teams"}
                        value={tournament.totalTeams}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item md={12}>
                <Box>
                  <PlatformTypeBar
                    types={
                      showRecentTab ? TypesAfterJoining : TypesBeforeJoining
                    }
                    val={typeFilter}
                    valHanlder={(val) => typeFilterHandler(val)}
                  />
                </Box>
                {typeFilter == "Schedule" &&
                  (scheduleData ? (
                    <Schedule id={id} scheduleData={scheduleData} />
                  ) : (
                    <Typography
                      mt={2}
                      component="p"
                      color="white"
                      className={classes.bottomBar}
                    >
                      NO SCHEDULE HERE
                      <span style={{ color: "red" }}>{scheduleErr}</span>
                    </Typography>
                  ))}
                {teamTabActive && (
                  <>
                    {/* <Typography component="p" color="white">
                  Tournament Teams
                </Typography> */}
                    {tournament &&
                      tournament?.tournamentTeamRecord?.length >= 1 ? (
                      <Grid container mt={2} className={classes.bottomBar}>
                        <Grid item md={7}>
                          <Grid container>
                            {tournament?.tournamentTeamRecord?.map((x, i) => (
                              <Grid
                                item
                                md={12}
                                lg={12 / 2}
                                xl={12 / 2}
                                xs={12}
                              >
                                <MediumTile
                                  key={i}
                                  roosters={x.roosters}
                                  teamName={x.teamViewName}
                                  teamLeader={x.teamLeader}
                                  teamImg={x.teamTitleImage}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={4} className={classes.winningBox}>
                          {winningTeam !== "" ? (
                            <WinningTeamTile
                              teamImg={winningTeam[0]?.teamTitleImage}
                              teamName={winningTeam[0]?.teamViewName}
                              matches={"0"}
                              win={winningTeam[0]?.winsCount}
                              winPerc={winningTeam[0]?.winPercentage}
                              loss={winningTeam[0]?.lossCount}
                            />
                          ) : (
                            <span>RESULT PENDING</span>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography
                        mt={2}
                        component="p"
                        color="white"
                        className={classes.bottomBar}
                      >
                        NO TEAM HERE
                      </Typography>
                    )}
                  </>
                )}

                {recentActivityTabActive &&
                  showRecentTab &&
                  !joinTournamentError && (
                    <>
                      {tournamentActivity?.length ? (
                        <>
                          {tournamentActivity.map((activity, index) => (
                            <Grid
                              container
                              mt={2}
                              className={classes.bottomBar}
                              key={activity._id}
                            >
                              <>
                                <Grid item md={7}>
                                  <Grid container>
                                    <Grid
                                      item
                                      md={12}
                                      lg={12 / 2}
                                      xl={12 / 2}
                                      xs={12}
                                    >
                                      <p>{activity?.text}</p>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item md={1}></Grid>
                                <Grid
                                  item
                                  md={4}
                                  className={classes.winningBox}
                                >
                                  <span>
                                    {getRelativetime(activity?.createdAt)}
                                  </span>
                                </Grid>
                              </>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <GeneralText
                          text="No Recent Activity Found!"
                          height="200px"
                        />
                      )}
                    </>
                  )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
