import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import addIcon from "../../../assets/addIcon.svg";
import dummyIcon from "../../../assets/addLeagueIcon.svg";
import { NotificationManager } from "react-notifications";

export default function imageUploadInventory({
  name,
  required,
  value,
  vidValue,
  onChange,
  disabled,
  setFieldTouched,
}) {
  const [imgSrc, setImgSrc] = useState(value);
  const [vidSrc, setVidSrc] = useState(vidValue);

  console.log({ value, vidValue })
  useEffect(() => {


  }, [vidSrc, imgSrc])

  const onChangeHandler = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    console.log({ file, reader })

    reader.onloadend = (e) => {
      if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg") {
        setImgSrc([reader.result])
        setVidSrc(null)
      }
      else if (file.type == "video/mp4") {
        setVidSrc([reader.result])
        setImgSrc(null)
      }
      else {
        NotificationManager.error("Please select .png, .jpg, .jpeg, or .mp4 file.")
      }
    };
    e.target.value = "";
    onChange(name, file);

  };

  return (
    <>
      <Grid container>
        <Grid
          item
          sx={{
            width: "100%",
            height: "200px",
            border: "1px solid #707070",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          {imgSrc ? (
            <img src={imgSrc} height="100%" width="100%" />
          ) :
            vidSrc ? <video src={vidSrc} width="340" height="180" controls /> :
              (
                <img src={dummyIcon} height="190px" width="120px" />
              )}

          <div style={{ position: "absolute", left: "47%", bottom: "-10px" }}>
            <label htmlFor="tournament-image-upload">
              <img src={addIcon} height="30px" width="30px" />
            </label>
            <input
              onBlur={() => setFieldTouched(name)}
              style={{ display: "none" }}
              id="tournament-image-upload"
              type="file"
              name={name}
              accept="image/*, video/*"
              disabled={disabled}
              multiple={false}
              onChange={(e) => onChangeHandler(e)}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
