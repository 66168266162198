import NoDataDiv from "../MatchHistory/components/no-data-div";
import TransactionDetailsRow from "./TransactionDetailsRow/transaction-details-row";

const UserDetailsTransaction = (props) => {
  return (
    <div id="transaction" className="">
      <div className="table_section">
        <table className="table">
          <thead className="thead">
            <tr>
              <th scope="col">Date & Time</th>
              <th scope="col">Type</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>

          <tbody>
            {props.data.transactionDetail.length ? (
              props.data.transactionDetail.map((transaction) => {
                return <TransactionDetailsRow data={{ ...transaction }} />;
              })
            ) : (
              <td colSpan={4}>
                <NoDataDiv text="No Data Found" className="col-4" />
              </td>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserDetailsTransaction;
