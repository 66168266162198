import { takeLatest, call, put } from "redux-saga/effects";
import {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
} from "./reducers";

import request from "../../../utils/apisauce";

function* getTransactionsSaga() {
  let action;
  try {
    const response = yield call(request, "GET", "/api/user/transaction-history");
    action = getTransactionsSuccess(response.result);
  } catch (e) {
    action = getTransactionsFailed(e);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getTransactions, getTransactionsSaga);
}
