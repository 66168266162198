import { takeLatest, call, put } from "redux-saga/effects";
import {
  getInventory,
  getInventorySuccess,
  getInventoryFailed,
  createInventory,
  createInventorySuccess,
  createInventoryFailed,
  updateInventory,
  updateInventorySuccess,
  updateInventoryFailed,
  deleteInventory,
  deleteInventorySuccess,
  deleteInventoryFailed,
  exportData,
  exportDataSuccess,
  exportDataFailed,
} from "./reducers";

import request from "../../../utils/apisauce";

function* getInventorySaga({ payload }) {
  let action;
  let url = "/api/inventory/list";
  try {
    if ("query" in payload) {
      url += `?name=${payload["query"]}`;
    }
    if (payload["pageNo"]) {
      url += `&pageNo=${payload["pageNo"]}`;
    }
    const response = yield call(request, "GET", url);
    action = getInventorySuccess(response);
  } catch (e) {
    action = getInventoryFailed(e);
  }
  yield put(action);
}

function* createInventorySaga({ payload }) {
  let action;
  try {
    const response = yield call(request, "POST", "/api/inventory/add", payload);
    action = createInventorySuccess(response);
    yield put(getInventory({ query: "" }));
  } catch (e) {
    action = createInventoryFailed(e);
  }
  yield put(action);
}

function* updateInventorySaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      "POST",
      `/api/inventory/update`,
      payload
    );
    action = updateInventorySuccess(response);
  } catch (e) {
    action = updateInventoryFailed(e);
  }
  yield put(action);
}

function* deleteInventorySaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      "POST",
      "/api/inventory/delete",
      payload
    );
    action = deleteInventorySuccess(response);
  } catch (e) {
    action = deleteInventoryFailed(e);
  }
  yield put(action);
}

function* exportDataSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      "GET",
      "/api/admin/exportInventoryData",
      payload
    );
    action = exportDataSuccess(response);
  } catch (e) {
    action = exportDataFailed(e);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(exportData, exportDataSaga);
  yield takeLatest(getInventory, getInventorySaga);
  yield takeLatest(createInventory, createInventorySaga);
  yield takeLatest(updateInventory, updateInventorySaga);
  yield takeLatest(deleteInventory, deleteInventorySaga);
}
