import { useState } from "react";
import friendImg from "../../image/3.png";
import trophyImg from "../../image/4.png";
import crossImg from "../../image/6.png";
import MatchDetailsRow from "./MatchDetailsRow/match-details-row";
import LadderDetailsRow from "./LadderDetailsRow/ladder-details-row";
import TournamentDetailsRow from "./TournamentDetailsRow/tournament-details-row";
import FantasyDetailsRow from "./FantasyLeagueDetailsRow/fantasy-details-row";
import GpDetailsRow from "./GpLeagueDetailsRow/gp-details-row";
import NoDataDiv from "./components/no-data-div";
import FilterTabs from "./components/filter-tabs";

const UserDetailsMatchHistory = (props) => {
  const [historyViewState, setHistoryViewState] = useState("one-to-one"); //'one-to-one', fantasy, tournament, gp-league, ladder
  const [filterTabValue, setFilterTabValue] = useState("General");

  let RowsToRender = [];

  if (historyViewState === "one-to-one") {
    RowsToRender = props.data.OneToOneMatches.map((match) => {
      return <MatchDetailsRow data={{ ...match }} />;
    });
  } else if (historyViewState === "ladder") {
    RowsToRender = props.data.ladderDetails.map((ladder) => {
      return <LadderDetailsRow data={{ ...ladder }} />;
    });
  } else if (historyViewState === "fantasy") {
    RowsToRender = props.data.fantasyLeagueDetails.map((fantasy) => {
      return <FantasyDetailsRow data={{ ...fantasy }} />;
    });
  } else if (historyViewState === "tournament") {
    if (filterTabValue === "General") {
      RowsToRender = props.data.tournamentDetails.filter((tournament)=>{
        return tournament.tournamentType === "general";
      }).map((tournament) => {
        return <TournamentDetailsRow data={{ ...tournament }} />;
      })
    }else if ("Grand Prix") {
      RowsToRender = props.data.tournamentDetails.filter((tournament)=>{
        return tournament.tournamentType === "franchise";
      }).map((tournament) => {
        return <TournamentDetailsRow data={{ ...tournament }} />;
      });
    }
  } else if (historyViewState === "gp-league") {
    RowsToRender = props.data.gpLeagueDetails.map((league) => {
      return <GpDetailsRow data={{ ...league }} />;
    });
  }

  return (
    <div id="match" className="">
      <div className="category-dropdown-wrapper">
        <select
          className="category-dropdown"
          onChange={(event) => setHistoryViewState(event.target.value)}
        >
          <option value="one-to-one" className="">
            1 to 1 Matches
          </option>
          <option value="ladder" className="">
            Ladder
          </option>
          <option value="gp-league" className="">
            GP League
          </option>
          <option value="tournament" className="">
            Tournament
          </option>
          <option value="fantasy" className="">
            Fantasy League
          </option>
        </select>
      </div>
      {historyViewState === "tournament" ? (
        <div style={{ margin: "15px auto" }}>
          <FilterTabs
            types={["General", "Grand Prix"]}
            val={filterTabValue}
            valHanlder={(val) => setFilterTabValue(val)}
          />
        </div>
      ) : null}
      <div className="table_section matches-list">
        <table className="table">
          <thead className="thead">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Game</th>
              <th scope="col">Prize</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody>
            {RowsToRender.length > 0 ? 
               RowsToRender
             : 
              <td colSpan={4}>
                <NoDataDiv text="No Data Found" className="col-4" />
              </td>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserDetailsMatchHistory;
