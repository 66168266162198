import { takeLatest, call, put } from "redux-saga/effects";
import {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
} from "./reducers";

import request from "../../../utils/apisauce";

function* getTransactionsSaga({ payload }) {
  let action;
  let url = "/api/withdraw/show-requests";
  try {
    // if ("query" in payload) {
    //   url += `?query=${payload["query"]}`;
    // }
    if (payload["pageNo"]) {
      url += `?pageNo=${payload["pageNo"]}`;
    }
    const response = yield call(request, "GET", url);
    action = getTransactionsSuccess(response);
  } catch (e) {
    action = getTransactionsFailed(e);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getTransactions, getTransactionsSaga);
}
