import crossImg from "../../../../image/6.png";
import trophyImg from "../../../../image/4.png";

const ResultDiv = (props) => {
  const color =
    props.result === "Won"
      ? "#33582d"
      : props.result === "Lost"
      ? "#e62e2d"
      : "#ad9418";
  const imgSrc =  props.result === "Won"? trophyImg:
                props.result === "Lost"? crossImg:
                '';             
    
  return (
    <div className="Result" style={{backgroundColor: color}}>
      <img src={imgSrc} alt="" />
      <span>{props.result}</span>
    </div>
  );
};

export default ResultDiv;
