import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TablePagination,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../inventoryCard/inventoryCard.scss";
import { NotificationManager } from "react-notifications";

import styles from "./table.module.scss";

export default function InventoryTable() {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const token = localStorage.getItem("userToken");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    from: 1,
    to: 0,
    total: 0,
  });

  const [inventoryList, setInventoryList] = useState([]);

  //Inventory Community List api integration using axios
  useEffect(() => {
    fetchInventoryList(1);
  }, []);

  const fetchInventoryList = (pageNo) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/inventory/listCommunityMarket?pageNo=${pageNo}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setInventoryList(response.data.result.data);
        setPagination(response.data.result.pagination);
      })
      .catch((error) => {
        console.error("Error fetching inventory list:", error);
      });
  };

  const handleBuyClick = (item) => {
    setSelectedItem(item);
    setConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  //Buy product api integration using axois
  const handleConfirmYes = () => {
    setConfirmModalOpen(false);

    const requestData = {
      userInventoryId: selectedItem.userInventoryId._id,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/inventory/buy`,
        requestData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setPurchaseModalOpen(true);
          // Request was successful
          NotificationManager.success("Request Successful");
        }
      })
      .catch((error) => {
        NotificationManager.error("Request Unsuccessful");
        console.error("Error calling BUY API:", error);
        // Handle the error or display an error message
      });
  };

  const handleClosePurchaseModal = () => {
    setPurchaseModalOpen(false);
  };
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/user/my-inventory");
  };

  //Pagination page change function
  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    fetchInventoryList(newPage + 1);
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            marginTop: "5px",
            borderCollapse: "separate !important",
            borderSpacing: "0 8px !important",
            border: "none !important",
          }}
        >
          <TableHead sx={{ backgroundColor: "gray", border: "none" }}>
            <TableRow sx={{ mb: 3, borderBottom: "none" }}>
              <TableCell align="left" sx={{ color: "white" }}>
                Inventory
              </TableCell>
              <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                Listed On
              </TableCell>
              <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                Price
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryList?.map((item) => (
              <TableRow
                key={item?._id}
                sx={{
                  bgcolor: "#2e2d2d",
                  marginBottom: "8px",
                  border: "none ",
                  "&:hover": {
                    border: `1px solid #F26826 important`,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{ color: "white" }}
                >
                  {item?.userInventoryId?.inventoryId?.link.endsWith("mp4") || item?.userInventoryId?.inventoryId?.link.endsWith('.avi') || item?.userInventoryId?.inventoryId?.link.endsWith('.mov') || item?.userInventoryId?.inventoryId?.link.endsWith('.wmv') ?
                    <video
                      src={`${process.env.REACT_APP_BASE_URL}/${item?.userInventoryId?.inventoryId?.link}`}
                      alt={""}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                      controls
                    /> :
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${item?.userInventoryId?.inventoryId?.link}`}
                      alt={""}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />}
                  {item?.userInventoryId?.inventoryId?.name}
                </TableCell>
                <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                  {new Date(item?.createdAt).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </TableCell>
                <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                  ${item?.price}
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#F26826", color: "white" }}
                    onClick={() => handleBuyClick(item)}
                  >
                    Buy
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          page={pagination.currentPage - 1}
          data={pagination.total}
          handleChangePage={handleChangePage}
          rowsPerPage={pagination.pageSize}
        />
        <Dialog
          open={confirmModalOpen}
          onClose={handleCloseConfirmModal}
          sx={{
            "& .MuiDialog-paper": {
              width: "438px",
              height: "203px",
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: "#1A1A1A",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "40px",
            }}
          >
            Are you sure you want to buy this item?
          </DialogTitle>

          <DialogActions
            sx={{
              backgroundColor: "#1A1A1A",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "70px",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleCloseConfirmModal}
              sx={{
                color: "white",
                bgcolor: "#F26826",
              }}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmYes}
              sx={{
                color: "white",
                bgcolor: "#F26826",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Purchase Modal */}

        <Dialog
          open={purchaseModalOpen}
          onClose={handleClosePurchaseModal}
          sx={{
            "& .MuiDialog-paper": {
              width: "632px",
              height: "249px",
              background: "#1A1A1A",
              border: "1px solid #FFFFFF4D",
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: "#1A1A1A",
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            {selectedItem && (
              <>
                {selectedItem?.userInventoryId?.inventoryId?.link.endsWith(".mp4") || selectedItem?.userInventoryId?.inventoryId?.link.endsWith('.avi') || selectedItem?.userInventoryId?.inventoryId?.link.endsWith('.mov') || selectedItem?.userInventoryId?.inventoryId?.link.endsWith('.wmv') ? <video
                  src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.userInventoryId?.inventoryId?.link}`}
                  alt={selectedItem?.inventoryId?.name}
                  style={{ width: "50px", height: "50px", marginRight: "10px" }}
                  controls
                /> : <img
                  src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.userInventoryId?.inventoryId?.link}`}
                  alt={selectedItem?.inventoryId?.name}
                  style={{ width: "50px", height: "50px", marginRight: "10px" }}
                />}
                <div>
                  <p style={{ margin: 0, color: "white" }}>{""}</p>
                  <p style={{ margin: 0, color: "white", fontSize: "14px" }}>
                    Price: {selectedItem?.price}
                  </p>
                </div>
              </>
            )}
          </DialogTitle>
          <div
            style={{
              width: "90%",
              margin: " auto",
            }}
          >
            {selectedItem && (
              <DialogContent
                sx={{
                  backgroundColor: "#282828",
                  color: "white",
                  padding: "10px",

                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <p>
                  <i
                    style={{
                      color: "#156502",
                    }}
                  >
                    Purchase Succeeded!
                  </i>
                  {selectedItem?.userInventoryId?.inventoryId?.name} is now in
                  your inventory, and your receipt has been emailed to you.
                </p>
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "16px",
                      display: "inline",
                      color: "#A1A1A1",
                    }}
                  >
                    {selectedItem?.price} has been deducted from your wallet.
                  </p>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#F26826",
                      color: "white",
                      marginLeft: "auto", // Aligns the button to the right side
                    }}
                    onClick={handleButtonClick}
                  >
                    View Inventory
                  </Button>
                </div>
              </DialogContent>
            )}
          </div>
        </Dialog>
      </TableContainer>
    </>
  );
}
const Pagination = ({ page, data, handleChangePage, rowsPerPage }) => {
  return (
    <TablePagination
      className={styles.pagination}
      rowsPerPageOptions={[rowsPerPage]}
      component="div"
      count={data || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
    />
  );
};
