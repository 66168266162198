import coinImg from "../../image/2.png";
import defaultImg from "../../../../../assets/teamprofile.png";
const baseURL = process.env.REACT_APP_BASE_URL;

const UserDetailsMain = (props) => {
  const receivedData = props.data ? props.data : {};


  return (
    <div className="popup-main row text-center">
      <div className="popup-img col-12 p-0 ">
        <img
          style={{ width: "150px", height: "150px" }}
          src={
            receivedData?.profileImage
              ? baseURL + "/" + receivedData?.profileImage
              : defaultImg
          }
          className="rounded-circle"
          alt="not show"
        />
      </div>

      <div className="popup-text col-12 mt-2 p-0">
        <h5 className="popup-title">{receivedData.fullName}</h5>
        <span className="popup-subtitle">({receivedData.userName})</span>
      </div>

      <div className="popup-sub-text col-12 mt-2 p-0">
        <h5 className="popup-title">Total Credits</h5>
        <span className="popup-subtitle">$ {receivedData.credits}</span>
      </div>
      <div className="popup-sub-text col-12 mt-2 p-0">
        <h5 className="popup-title">Payment Status</h5>
        {receivedData?.paymentStatus === true ? <span className="popup-subtitle">Paid </span> : <span className="popup-subtitle">Unpaid </span>}
      </div>
    </div>
  );
};

export default UserDetailsMain;
