import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NameEdit from "./Modal/nameEdit";
import AboutEdit from "../../components/aboutEditModal/aboutEdit";
import TopSection from "./components/topSection";
import BottomSection from "./components/bottomSection/bottomSection";
import Loader from "../../components/Loader/Loader";
import "./index.css";
import {
  getUser,
  updateProfileBg,
  updateProfileImg,
  updateUser,
  getUserInventory,

} from "./reducers";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.userProfile);
  const loading = useSelector((state) => state.userProfile.loading);
  const [nameEdit, setNameEdit] = useState(false);

  const [aboutEdit, setAboutEdit] = useState(false);
  const [games, setGames] = useState([])
  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      dispatch(getUser());
      dispatch(getUserInventory());
    } else {
      navigate("/auth/login");
    }
  }, []);

  const { profile, inventory } = userProfile;
  const handleClose = () => {
    setAboutEdit(false);
    setNameEdit(false);
  };
  const handleUpdateUser = (val) => {
    dispatch(updateUser(val));
    setAboutEdit(false);
    setNameEdit(false);
  };
  const userProfileImgHandler = (e, type) => {
    dispatch(updateProfileImg(e));
  };
  const OBGprofileBgImgHandler = (e) => {
    dispatch(updateProfileBg(e));
  };

  return (
    <>
      {loading ? (
        <Loader color={1} />
      ) : (
        <>
          <NameEdit
            fullName={profile?.userDetail?.fullName}
            open={nameEdit && true}
            handleClose={handleClose}
            handleUpdateUser={handleUpdateUser}
          />
          <AboutEdit
            about={profile?.userDetail?.about}
            open={aboutEdit && true}
            handleClose={handleClose}
            handleUpdateUser={handleUpdateUser}
          />
          <div className="userprofile-containerWrapper ">
            <TopSection
              profile={profile}
              OBGprofileBgImgHandler={OBGprofileBgImgHandler}
              userProfileImgHandler={userProfileImgHandler}
              setNameEdit={setNameEdit}
            />
            {profile && (
              <BottomSection
                games={games}
                profile={profile}
                inventory={inventory}
                setAboutEdit={setAboutEdit}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
