import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import DynamicButton from "../../components/dynamicButton/dynamicButton";
import "./index.module.scss";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import InventoryTable from "../../components/communitMarketTable/communityMarketTable";
import MyMarketHistoryTable from "../../components/mymarketHistoryTable/myMarketHistoryTable";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import BackChevron from "../../../common/backChevron/backChevron";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function CommunityMarket() {
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isSellDialogOpen, setIsSellDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isConfirmSellDialogOpen, setIsConfirmSellDialogOpen] = useState(false);
  const [isForSaleDialogOpen, setIsForSaleDialogOpen] = useState(false);
  const [price, setPrice] = useState("");
  const [inventoryData, setInventoryData] = useState([]);
  const token = localStorage.getItem("userToken");

  //inventory List api integrating using axois
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/inventory/listMyInventory`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const { result } = response.data;
        setInventoryData(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    sellInventoryItem();
    navigate("/user/my-inventory");
  };

  const handleImageClick = (Item) => {
    setSelectedItem(Item);
  };

  const sellData = {
    userInventoryId: selectedItem._id,
    price: price,
  };

  const sellInventoryItem = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/inventory/sell`, sellData, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        NotificationManager.success("Request Successful");
      })
      .catch((error) => { });
  };
  const handlePriceChange = (event) => {
    const inputPrice = event.target.value;

    const isValidNumber = /^\d*$/.test(inputPrice);

    if (isValidNumber) {
      const priceValue = inputPrice === "" ? "" : parseInt(inputPrice, 10);
      setPrice(priceValue);
    } else {
      NotificationManager.error("Please Enter a Valid Number!");
    }
  };

  const handleSellanItemButtonClick = () => {
    setIsImageDialogOpen(true);
  };

  const handleSellanIamgeButtonClick = () => {
    if (Object.keys(selectedItem).length === 0) {
      NotificationManager.error("Please Select an image!");
      return;
    }

    setIsSellDialogOpen(true);
    setIsImageDialogOpen(false);
  };

  const handleSellDialogClick = () => {
    setIsConfirmSellDialogOpen(true);
    setIsSellDialogOpen(false);
  };

  const handleisForSellDialogClick = () => {
    setIsForSaleDialogOpen(true);
    setIsConfirmSellDialogOpen(false);
  };
  const handleImageDialogClose = () => {
    setIsImageDialogOpen(false);
  };

  const handleSellDialogClose = () => {
    setIsSellDialogOpen(false);
  };

  const handleIsForSellDialogClose = () => {
    setIsForSaleDialogOpen(false);
  };

  const handleConfirmSellDialogClose = () => {
    setIsSellDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ marginBottom: "16px" }}>
        <Grid>
          <Typography
            component="span"
            sx={{ color: "white", fontSize: 24, fontWeight: "bold" }}
          >
            COMMUNITY MARKET
          </Typography>
          <Typography
            sx={{ color: "gray", fontSize: 12, fontWeight: "bold", mb: "12px" }}
          >
            Buy and sell inventories
          </Typography>
        </Grid>
        <Box
          sx={{
            bgcolor: "#2e2d2d",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
            paddingRight: "5px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab
              label="Popular Items"
              sx={{
                color: "white",
                fontWeight: "bold",
                bgcolor: selectedTab === 0 ? "#F26826" : "transparent",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
            <Tab
              label="My Market History"
              sx={{
                color: "white",
                fontWeight: "bold",
                bgcolor: selectedTab === 1 ? "#F26826" : "transparent",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
          </Tabs>

          {isMobile ? (
            <Box sx={{ marginTop: "12px" }}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#F26826",
                  color: "white",
                  "&:hover": { bgcolor: "#F26826" },
                }}
                onClick={handleSellanItemButtonClick}
              >
                Sell an Item
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              sx={{
                bgcolor: "#F26826",
                color: "white",
                "&:hover": { bgcolor: "#F26826" },
              }}
              onClick={handleSellanItemButtonClick}
            >
              Sell an Item
            </Button>
          )}
        </Box>
        <IconButton
          onClick={() => navigate(-1)}
          style={{
            top: "2%",

            background: "rgba(0, 0, 0, 0.6)",
            marginRight: "6px",
          }}
        >
          <ChevronLeftIcon sx={{ color: "#F26826" }}></ChevronLeftIcon>
        </IconButton>
        <Typography
          sx={{
            color: "gray",
            fontSize: 12,
            fontWeight: "bold",
            top: "12px",
            display: "inline-block",
            marginBottom: "30px",
          }}
        >
          Back to inventory
        </Typography>

        {selectedTab === 0 ? <InventoryTable /> : <MyMarketHistoryTable />}
      </Box>

      {/* imageDialog */}
      <Dialog
        open={isImageDialogOpen}
        onClose={handleImageDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            width: "632px",
            height: "313px",
            background: "#1A1A1A",
            border: "1px solid #FFFFFF4D",
            borderRadius: "8px",
            opacity: 1,
          },
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            {inventoryData.map((item) => (
              <>
                {item.inventoryId.forSale === true ? (
                  <Grid item key={item._id} xs={6} sm={3} md={3}>
                    {item?.inventoryId?.link.endsWith(".mp4") || item?.inventoryId?.link.endsWith('.avi') || item?.inventoryId?.link.endsWith('.mov') || item?.inventoryId?.link.endsWith('.wmv') ? <video
                      src={`${process.env.REACT_APP_BASE_URL}/${item?.inventoryId?.link}`}
                      className="profileimage"
                      alt={item.inventoryId?.name}
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "8px",
                        objectFit: "cover",
                        cursor: "pointer",
                        border:
                          selectedItem?._id === item?._id
                            ? "1px solid"
                            : "none",
                        transition: "border-color 0.3s ease",
                        borderColor:
                          selectedItem?._id === item?._id
                            ? "#F3773C"
                            : "transparent",
                      }}
                      controls
                      onClick={() => handleImageClick(item)}
                    /> : <img
                      src={`${process.env.REACT_APP_BASE_URL}/${item?.inventoryId?.link}`}
                      className="profileimage"
                      alt={item.inventoryId?.name}
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "8px",
                        objectFit: "cover",
                        cursor: "pointer",
                        border:
                          selectedItem?._id === item?._id
                            ? "1px solid"
                            : "none",
                        transition: "border-color 0.3s ease",
                        borderColor:
                          selectedItem?._id === item?._id
                            ? "#F3773C"
                            : "transparent",
                      }}
                      onClick={() => handleImageClick(item)}
                    />}
                  </Grid>
                ) : null}
              </>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#F26826",
              color: "white",
              "&:hover": { bgcolor: "#F26826" },
            }}
            onClick={handleSellanIamgeButtonClick}
          >
            Sell
          </Button>
        </DialogActions>
      </Dialog>

      {/* sellDialog */}

      <Dialog
        open={isSellDialogOpen}
        onClose={handleSellDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            width: "632px",
            height: "266px",
            background: "#1A1A1A",
            border: "1px solid #FFFFFF4D",
            borderRadius: "8px",
            opacity: 1,
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "white",
          }}
        >
          Put an item up for sale
        </DialogTitle>
        <DialogContent>
          {selectedItem && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                {selectedItem?.inventoryId?.link.endsWith(".mp4") || selectedItem?.inventoryId?.link.endsWith('.avi') || selectedItem?.inventoryId?.link.endsWith('.mov') || selectedItem?.inventoryId?.link.endsWith('.wmv') ?
                  <video
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    controls
                    height="80" width="140"
                  /> : <img
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    style={{ width: "71px", height: "59px" }}
                  />}
                <span style={{ margin: 0, color: "white" }}>
                  {selectedItem?.inventoryId?.name}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <span style={{ color: "white" }}>Price: $ </span>
                <TextField
                  variant="outlined"
                  size="small"
                  style={{
                    width: "83px",
                    height: "42px",
                    border: "1px solid #696969",
                    borderRadius: "4px",
                    opacity: "1",
                    color: "white",
                    textAlign: "center",
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  value={price}
                  onChange={handlePriceChange}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#F26826",
              color: "white",
              "&:hover": { bgcolor: "#F26826" },
            }}
            onClick={handleSellDialogClick}
          >
            Put it up for sale
          </Button>
        </DialogActions>
      </Dialog>

      {/* confirmsellDialog */}

      <Dialog
        open={isConfirmSellDialogOpen}
        onClose={handleConfirmSellDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            width: "632px",
            height: "266px",
            background: "#1A1A1A",
            border: "1px solid #FFFFFF4D",
            borderRadius: "8px",
            opacity: 1,
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "white",
          }}
        >
          Put an item up for sale
        </DialogTitle>
        <DialogContent>
          {selectedItem && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                {selectedItem?.inventoryId?.link.endsWith(".mp4") || selectedItem?.inventoryId?.link.endsWith('.avi') || selectedItem?.inventoryId?.link.endsWith('.mov') || selectedItem?.inventoryId?.link.endsWith('.wmv') ?
                  <video
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    controls
                    height="80" width="140"
                  /> : <img
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    style={{ width: "71px", height: "59px" }}
                  />}
                <span style={{ margin: 0, color: "white" }}>
                  {selectedItem?.inventoryId?.name}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "white" }}>Price: $</span>

                <TextField
                  variant="outlined"
                  size="small"
                  style={{
                    width: "83px",
                    height: "42px",
                    border: "1px solid #696969",
                    borderRadius: "4px",
                    opacity: "1",
                    color: "white",
                    textAlign: "center",
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  value={price}
                  readOnly={true}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <div
            style={{
              width: "90%",
              margin: " auto",
            }}
          >
            {selectedItem && (
              <DialogContent
                sx={{
                  backgroundColor: "#282828",
                  color: "white",
                  padding: "10px",

                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "16px",
                      display: "inline",
                      color: "#A1A1A1",
                    }}
                  >
                    This item will not available in your inventory for use or
                    trade while it is for sale in Community Market.
                  </p>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#F26826",
                      color: "white",
                      "&:hover": { bgcolor: "#F26826" },
                    }}
                    onClick={handleisForSellDialogClick}
                  >
                    ok
                  </Button>
                </div>
              </DialogContent>
            )}
          </div>
        </DialogActions>
      </Dialog>

      {/* isForSaleDialog */}

      <Dialog
        open={isForSaleDialogOpen}
        onClose={handleIsForSellDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "632px",
            height: "186px",
            background: "#1A1A1A",
            border: "1px solid #FFFFFF4D",
            borderRadius: "8px",
          },
        }}
      >
        <div
          style={{
            width: "90%",
            margin: " auto",
          }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#282828",
              color: "white",
              padding: "10px",

              marginBottom: "10px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex ",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "16px",
                  display: "inline",
                  color: "#A1A1A1",
                }}
              >
                Your item will list on Community Market. When it sells, you will
                get an email, and money from the sale will appear in your
                wallet.
              </p>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#F26826",
                  color: "white",
                  marginLeft: "auto", // Aligns the button to the right side
                }}
                onClick={handleButtonClick}
              >
                Ok
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}
