import "./user-details-modal.css";

import UserDetailsMain from "./components/Main/user-details-main";
import UserDetailsNav from "./components/UserDetailsNav/user-details-nav";
import UserDetailsInfo from "./components/Info/user-details-info";
import UserDetailsTransaction from "./components/Transaction/user-details-transaction";
import UserDetailsMatchHistory from "./components/MatchHistory/user-details-match-history";
import UserInventory from "./components/Inventory/user-inventory";

import { Modal } from "@mui/material";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailsInfo,
  getUserOneToOneMatchesDetails,
  getUserTransactionDetails,
  getUserFantasyLeagueDetails,
  getUserGpLeagueDetails,
  getUserLadderDetails,
  getUserTournamentDetails,
  getUserInventory,
} from "./reducers";

const OkButton = styled(Button)(({ color }) => {
  return {
    backgroundColor: color ? "#4A4A4A" : "#F26826",
    padding: "7px 65px",
    color: "white",
    margin: "0px auto",
    "& :hover": {
      // backgroundColor: color ? "#4A4A4A" : "#F26826",
    },
  };
});

const MyModal = styled(Modal)({
  "& .MuiBox-root": {
    background: "#1a1a1a",
  },
});

const UserDetailsModal = ({ open, data, handleClose }) => {
  const [detailsNavState, setDetailsNavState] = useState("info");
  // 'info', 'transaction', 'history inventory'
  const userId = data ? data._id : "";
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const userInventory = useSelector((state) => state.userDetails.userInventory);
  useEffect(() => {
    dispatch(getUserDetailsInfo({ _id: userId }));
    dispatch(getUserOneToOneMatchesDetails({ _id: userId }));
    dispatch(getUserTransactionDetails({ _id: userId }));
    dispatch(getUserFantasyLeagueDetails({ _id: userId }));
    dispatch(getUserGpLeagueDetails({ _id: userId }));
    dispatch(getUserLadderDetails({ _id: userId }));
    dispatch(getUserTournamentDetails({ _id: userId }));
    dispatch(getUserInventory({ _id: userId }));
  }, [userId]);

  const OkButton = styled(Button)(({ color }) => {
    return {
      backgroundColor: color ? "#4A4A4A" : "#F26826",
      padding: "7px 40px",
      color: "white",
      margin: "0px auto",
      "& :hover": {
        // backgroundColor: color ? "#4A4A4A" : "#F26826",
      },
    };
  });

  return (
    <MyModal
      className="pupup-modal-wrapper"
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="popup-dialog">
        <div className="popup-content">
          <UserDetailsMain
            data={{
              profileImage: userDetails.userInfo.profileImage,
              fullName: userDetails.userInfo.fullName,
              userName: userDetails.userInfo.userName,
              credits: userDetails.userInfo.credits,
              paymentStatus: userDetails?.userInfo?.paymentStatus
            }}
          />
          <div className="popup-body">
            <div className="container">
              <UserDetailsNav navStateHandler={setDetailsNavState} />
              <div className="tab-content">
                {detailsNavState === "info" ? (
                  <UserDetailsInfo
                    data={{
                      friends: userDetails.userInfo.friends,
                      totalTeams: userDetails.userInfo.totalTeams,
                      generalTeams: userDetails.userInfo.generalTeams,
                      franchiseTeams: userDetails.userInfo.franchiseTeams,
                      franchiseMode: userDetails.userInfo.franchiseMode,
                      franchiseName: userDetails.userInfo.franchiseName,
                      franchiseOwnerName:
                        userDetails.userInfo.franchiseOwnerName,
                    }}
                  />
                ) : detailsNavState === "transaction" ? (
                  <UserDetailsTransaction
                    data={{ ...userDetails.userTransaction }}
                  />
                ) : detailsNavState === "history" ? (
                  <UserDetailsMatchHistory
                    data={{ ...userDetails.userMatchHistory }}
                  />
                ) : detailsNavState === "inventory" ? (
                  <UserInventory data={{ userId, userInventory }} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="m-auto ok-button-wrapper">
            <OkButton type="button" onClick={() => handleClose(false)}>
              {"Ok"}
            </OkButton>
          </div>
        </div>
      </div>
    </MyModal>
  );
};
export default UserDetailsModal;
