import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import edit from "../../../../../assets/edit.svg";
import classes from "./bottomSection.module.scss";
import MatchCard from "../../../../components/matchCard/matchCard";
import DummyCard from "../../../../components/dummyCard/dummyCard";
import { capitalize } from "../../../../../utils/apisauce";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { Tooltip } from "@material-ui/core";

const BASE_URL = process.env.REACT_APP_BASE_URL + "/";

export default function BottomSection({ games, profile, setAboutEdit, inventory }) {
  console.log("profile = ", profile);
  const { currentMatch } = profile;
  const {
    userDetail: { matches, wins, losses, winPercentage, recentResults },
  } = profile;
  const favGamesId = [];


  const handleFav = (id) => {
    favGamesId.push(id)
    console.log({ favGamesId })
    axios
      .post(`${BASE_URL}api/user/update`, {
        _id: profile?._id,
        favouriteGames: favGamesId
      }, {
        headers: {
          "Authorization": `${localStorage.getItem("userToken")}`
        }
      }).then((res) => {
        // console.log({ res })
        NotificationManager.success("Game Added to Favourite")
      }).catch((err) => {
        NotificationManager.success("Opps~! Try again")
        // console.log({ err })
      })

  }
  return (
    <Grid container marginY={"3%"} className={classes.scrollWrapper}>
      <Grid item md={8.5} sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ color: "#767676" }}>Overview&nbsp;</Typography>
        <Box className={classes.leftRectangle}>
          <Grid container>
            <Grid item md={12} lg={6}>
              <Typography sx={{ color: "#767676", textAlign: "left" }}>
                Current Match
              </Typography>
              {currentMatch?._id ? (
                <MatchCard
                  link={"/user/my-matches/" + currentMatch._id}
                  img={
                    currentMatch.matchTitleImage
                      ? BASE_URL + currentMatch.matchTitleImage
                      : null
                  }
                  name={currentMatch.matchName}
                  challengeBy={currentMatch?.challengeBy}
                  challengeTo={currentMatch?.challengeTo}
                  prize={currentMatch.prize}
                  date={currentMatch.startDate}
                  time={currentMatch.startTime}
                  status={currentMatch.status}
                />
              ) : (
                <DummyCard text="No Match Found!" />
              )}
            </Grid>
            <Grid item md={12} lg={6}>
              <Grid container className={classes.stats}>
                <Grid item className={classes.stateItem}>
                  <p className={classes.statsHead}>Matches</p>
                  <p>{matches}</p>
                </Grid>
                <Grid item className={classes.stateItem}>
                  <p className={classes.statsHead}>Wins</p>
                  <p>{wins}</p>
                </Grid>
                <Grid item className={classes.stateItem}>
                  <p className={classes.statsHead}>Losses</p>
                  <p>{losses}</p>
                </Grid>
                <Grid item className={classes.stateItem}>
                  <p className={classes.statsHead}>Win%</p>
                  <p>{winPercentage}</p>
                </Grid>
                <Grid item className={classes.stateItem}>
                  <p className={classes.statsHead}>Recent Result</p>
                  {recentResults?.length
                    ? recentResults?.map((x, i) => (
                      <span
                        className={
                          x === "W" ? classes.successText : classes.dangerText
                        }
                        key={i}
                      >
                        {x}&nbsp;
                      </span>
                    ))
                    : "--"}
                </Grid>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={0.5}></Grid>
      <Grid item md={3} className={classes.rightRectangle}>
        <Typography sx={{ color: "#767676", padding: "2%" }}>
          About&nbsp;
          <img
            alt=""
            src={edit}
            onClick={() => setAboutEdit(true)}
            style={{ cursor: "pointer" }}
          />
        </Typography>
        <Typography className={classes.franchAboutSec}>
          {profile?.userDetail?.about && capitalize(profile?.userDetail?.about)}
        </Typography>
      </Grid>
      <Grid item md={12} mt={3}>
        <Typography sx={{ color: "#767676" }}>My Inventory&nbsp;</Typography>
        <Box className={classes.leftInventoryRectangle}>
          <Grid container gap={2} className={classes.inventoryItemsWrapper}>
            {inventory?.map((item) => {
              return (
                <Grid item className={classes.inventoryItem}>
                  <div className={classes.imgWrapper}>
                    {item.inventoryId.link.endsWith('.mp4') || item.inventoryId.link.endsWith('.avi') || item.inventoryId.link.endsWith('.mov') || item.inventoryId.link.endsWith('.wmv') ?

                      <video src={BASE_URL + item?.inventoryId?.link} width="240" height="100%" controls />
                      :
                      <img
                        src={BASE_URL + item?.inventoryId?.link}
                        className={classes.img}
                      />
                    }
                  </div>
                  <div className={classes.title}>{item?.inventoryId?.name}</div>
                </Grid>
              );
            })}
            {inventory?.length <= 0 && (
              <Grid item>
                <DummyCard text="No Inventory Found!" />
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>

      <Grid item md={12} mt={3}  >
        <Typography sx={{ color: "#767676" }}>Favourites Games&nbsp;</Typography>
        <Box style={{ display: "flex", flexWrap: "wrap" }} className={classes.leftInventoryRectangle}>
          <Grid gap={2} container className={classes.gamesListItemsWrapper}>
            {profile?.favouriteGames?.map((item) => {
              return (
                <Grid item className={classes.inventoryItem}>
                  <div className={classes.imgWrapper}>
                    <img style={{ height: "100%", width: "100%" }} src={BASE_URL + item?.gameImage} />
                  </div>
                  <Tooltip title={item?.gameName}>
                    <div style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis', color: "white"
                    }} className={classes.title}>{item?.gameName}  </div>
                  </Tooltip>
                </Grid>
              );
            })}
            {profile?.favouriteGames?.length <= 0 && (
              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <h1>No Games Found</h1>
              </div>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid >
  );
}
