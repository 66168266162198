import React, { useState } from "react";
import { Formik } from "formik";
// import Input from "../../../../components/Input/input";
import profileSelfImg from "../../../../../assets/teamprofile.png";
// import Modal from "../../../../../User/components/topModal/topModal";
import FormSubmitButton from "../../../../components/FormSubmitButton/formSubmitbutton";
import { Grid, Typography } from "@mui/material";
import classes from "./killPointsModal.module.scss";
import { useFormik } from "formik";
import { Modal, Form, Button, Row, Col, Container } from "react-bootstrap";
import { useEffect } from "react";

const BASE_URL = process.env.REACT_APP_BASE_URL + "/";

export default function KillPointsModal({
  open,
  row,
  handleClose,
  resultStatus,
  handleUploadResult,
}) {
  //   //prepare obj for initialValues, having ids and keys and values of that keys
  //   let formObj = [
  //     {
  //       userId: 1,
  //       killPoints: 100,
  //       ace: 1,
  //       bombPlanted: 2,
  //       bombDiffused: 4,
  //     },
  //   ];

  // // const { killPointsArr } = row.killPointsArr;

  // Create state variables to track the form input values

  // const [formValues, setFormValues] = useState({});
  // useEffect(() => {
  //   if (row && row.gameName) {
  //     setFormValues(row);
  //   }
  // }, [row]);

  // // Handle input change event
  // const handleInputChange = (index, event) => {
  //   const { name, value } = event.target;

  //   // Update the corresponding value in the formValues state
  //   setFormValues((prevFormValues) => {
  //     const updatedFormValues = [...prevFormValues];
  //     updatedFormValues[index] = {
  //       ...updatedFormValues[index],
  //       [name]: value,
  //     };
  //     return updatedFormValues;
  //   });
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   handleUploadResult(formValues?.killPointsArr);
  // };

  // return (
  //   <Modal show={open} onHide={handleClose}>
  //     <Modal.Header closeButton style={{ backgroundColor: "#f26826" }}>
  //       <Modal.Title style={{ color: "white" }}>Upload Result</Modal.Title>
  //     </Modal.Header>
  //     <Modal.Body style={{ backgroundColor: "#1a1a1a" }}>
  //       <Form onSubmit={handleSubmit}>
  //         {formValues?.killPointsArr?.map((killPointsData, index) => (
  //           <React.Fragment key={index}>
  //             <Form.Group className="mb-3">
  //               <Form.Label style={{ color: "white" }}>User Name</Form.Label>
  //               <Form.Control
  //                 type="text"
  //                 value={killPointsData.userName}
  //                 name={`killPointsArr[${index}].userName`}
  //                 placeholder="UserName"
  //                 onChange={(event) => handleInputChange(index, event)}
  //               />
  //             </Form.Group>

  //             <Form.Group className="mb-3">
  //               <Form.Label style={{ color: "white" }}>Kill Points</Form.Label>
  //               <Form.Control
  //                 type="number"
  //                 placeholder="Kill Points"
  //                 name={`killPointsArr[${index}].killPoints`}
  //                 value={killPointsData.killPoints || 0}
  //                 onChange={(event) => handleInputChange(index, event)}
  //               />
  //             </Form.Group>
  //             <Form.Group className="mb-3">
  //               <Form.Label style={{ color: "white" }}>Ace</Form.Label>
  //               <Form.Control
  //                 type="number"
  //                 placeholder="Ace"
  //                 name={`killPointsArr[${index}].ace`}
  //                 value={killPointsData.ace || 0}
  //                 onChange={(event) => handleInputChange(index, event)}
  //               />
  //             </Form.Group>
  //             <Form.Group className="mb-3">
  //               <Form.Label style={{ color: "white" }}>Bomb Planted</Form.Label>
  //               <Form.Control
  //                 type="number"
  //                 placeholder="Bomb Planted"
  //                 name={`killPointsArr[${index}].bombPlanted`}
  //                 value={killPointsData.bombPlanted || 0}
  //                 onChange={(event) => handleInputChange(index, event)}
  //               />
  //             </Form.Group>
  //             <Form.Group className="mb-3">
  //               <Form.Label style={{ color: "white" }}>
  //                 Bomb Diffused
  //               </Form.Label>
  //               <Form.Control
  //                 type="number"
  //                 placeholder="Bomb Diffused"
  //                 name={`killPointsArr[${index}].bombDiffused`}
  //                 value={killPointsData.bombDiffused || 0}
  //                 onChange={(event) => handleInputChange(index, event)}
  //               />
  //             </Form.Group>
  //           </React.Fragment>
  //         ))}
  //         <Button variant="secondary" onClick={handleClose}>
  //           Close
  //         </Button>
  //         <Button variant="primary" type="submit" className="mx-1">
  //           Upload
  //         </Button>
  //       </Form>
  //     </Modal.Body>
  //   </Modal>
  // );

  // const [pointsArr, setPointsArr] = useState({});
  // useEffect(() => {
  //   if (row && row.gameName) {
  //     setPointsArr(row);
  //   }
  // }, [row]);
  // useEffect(() => {
  // }, [pointsArr]);

  // const handlePointsChange = (index, field, value) => {
  //   setPointsArr((prevPointsArr) => {
  //     const updatedPointsArr = [...prevPointsArr];
  //     updatedPointsArr[index][field] = value;
  //     return updatedPointsArr;
  //   });
  // };

  // const handleSubmit = () => {
  //   handleUploadResult(pointsArr?.killPointsArr);
  // };

  const [pointsArr, setPointsArr] = useState([]);
  const [members ,setMembers] = useState([]);
  useEffect(() => {
    if (row && row.gameToPlay) {
      if (row?.killPointsArr.length) setPointsArr(row?.killPointsArr);
      else {
        let tempArr = [];
        for (let i = 0; i < row.teamMembers.length; i++) {
          tempArr.push({
            userId: row.teamMembers[i]._id,
            bombDiffused: 0,
            bombPlanted: 0,
            ace: 0,
            killPoints: 0,
          });
        }
        setPointsArr(tempArr);
      }
      console.log("points Arrauy", row);
    }
    if(row && row?.teamMembers){
      setMembers(row.teamMembers)
    }
  }, [row]);
  const handlePointsChange = (index, field, value) => {
    setPointsArr((prevPointsArr) => {
      const updatedPointsArr = [...prevPointsArr];
      updatedPointsArr[index] = {
        ...updatedPointsArr[index],
        [field]: value,
      };
      return updatedPointsArr;
    });
  };
  console.log({row})

  const handleSubmit = () => {
    let obj = {
      playerKillPoints: pointsArr,
      resultId: row._id,
      resultStatus: resultStatus,
    };
    handleUploadResult(obj);
  };

  function findUserNameById(members, userId) {
    const member = members.find(member => member._id === userId);
    return member ? member.userName : null;
  }
  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton style={{ background: "#1a1a1a" }}>
        <Modal.Title style={{ color: "white" }}>
          User Points Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#1a1a1a" }}>
        <Container>
          {pointsArr.map((user, index) => (
            <Row style={{ border: "1px solid white" }} className="my-2">
              <Col lg={12} md={12} sm={12} className="my-1">
                <label style={{ color: "white" }}>
                  User ID: {findUserNameById(members,user?.userId)}
                </label>
              </Col>
              <Col lg={6} md={6} sm={6} className="my-2">
                <label
                  htmlFor={`killPoints__${index}`}
                  style={{ color: "white" }}
                >
                  Kill Points:
                </label>
                <input
                  type="text"
                  id={`killPoints_${index}`}
                  className="form-control userField"
                  value={user.killPoints}
                  onChange={(e) =>
                    handlePointsChange(index, "killPoints", e.target.value)
                  }
                />
              </Col>
              {/* <Col lg={6} md={6} sm={6} className="my-2">
                <label htmlFor={`Ace_${index}`} style={{ color: "white" }}>
                  Ace:
                </label>
                <input
                  type="number"
                  id={`ace_${index}`}
                  className="form-control userField"
                  value={user.ace}
                  onChange={(e) =>
                    handlePointsChange(index, "ace", e.target.value)
                  }
                />
              </Col>
              <Col lg={6} md={6} sm={6} className="my-2">
                <label
                  htmlFor={`bombPlanted_${index}`}
                  style={{ color: "white" }}
                >
                  Bomb Planted:
                </label>
                <input
                  type="number"
                  id={`bombPlanted_${index}`}
                  className="form-control userField"
                  value={user.bombPlanted}
                  onChange={(e) =>
                    handlePointsChange(index, "bombPlanted", e.target.value)
                  }
                />
              </Col>
              <Col lg={6} md={6} sm={6} className="my-2">
                <label
                  htmlFor={`bombDiffused_${index}`}
                  style={{ color: "white" }}
                >
                  Bomb Diffused:
                </label>
                <input
                  type="number"
                  id={`bombDiffused_${index}`}
                  className="form-control userField"
                  value={user.bombDiffused}
                  onChange={(e) =>
                    handlePointsChange(index, "bombDiffused", e.target.value)
                  }
                />
              </Col> */}
            </Row>
          ))}
        </Container>

        {/* 
          // <div key={user.userId} className="form-group">
          //   <label style={{ color: "white" }}>User ID: {user.userId}</label>
          //   <br></br>
          //   <label htmlFor={`killPoints__${index}`} style={{ color: "white" }}>
          //     Kill Points:
          //   </label>
          //   <input
          //     type="text"
          //     id={`killPoints_${index}`}
          //     className="form-control userField"
          //     value={user.killPoints}
          //     onChange={(e) =>
          //       handlePointsChange(index, "killPoints", e.target.value)
          //     }
          //   />
          //   <label htmlFor={`Ace_${index}`} style={{ color: "white" }}>
          //     Ace:
          //   </label>
          //   <input
          //     type="number"
          //     id={`ace_${index}`}
          //     className="form-control userField"
          //     value={user.ace || 0}
          //     onChange={(e) => handlePointsChange(index, "ace", e.target.value)}
          //   />
          //   <label htmlFor={`bombPlanted_${index}`} style={{ color: "white" }}>
          //     Bomb Planted:
          //   </label>
          //   <input
          //     type="number"
          //     id={`bombPlanted_${index}`}
          //     className="form-control userField"
          //     value={user.bombPlanted || 0}
          //     onChange={(e) =>
          //       handlePointsChange(index, "bombPlanted", e.target.value)
          //     }
          //   />
          //   <label htmlFor={`bombDiffused_${index}`} style={{ color: "white" }}>
          //     Bomb Diffused:
          //   </label>
          //   <input
          //     type="number"
          //     id={`bombDiffused_${index}`}
          //     className="form-control userField"
          //     value={user.bombDiffused || 0}
          //     onChange={(e) =>
          //       handlePointsChange(index, "bombDiffused", e.target.value)
          //     }
          //   />
          // </div>

          // <div key={user.userId} className={classes.userrow}>
          //   <div className={classes.inputgroup}>
          //     <label style={{ color: "white" }}>User ID: {user.userId}</label>
          //   </div>
          //   <div className={classes.inputgroup}>
          //     <label
          //       htmlFor={`killPoints__${index}`}
          //       style={{ color: "white" }}
          //     >
          //       Kill Points:
          //     </label>
          //     <input
          //       type="text"
          //       id={`killPoints_${index}`}
          //       className="form-control userField"
          //       value={user.killPoints}
          //       onChange={(e) =>
          //         handlePointsChange(index, "killPoints", e.target.value)
          //       }
          //     />
          //   </div>
          //   <div className={classes.inputgroup}>
          //     <label htmlFor={`Ace_${index}`} style={{ color: "white" }}>
          //       Ace:
          //     </label>
          //     <input
          //       type="number"
          //       id={`ace_${index}`}
          //       className="form-control userField"
          //       value={user.ace || 0}
          //       onChange={(e) =>
          //         handlePointsChange(index, "ace", e.target.value)
          //       }
          //     />
          //   </div>
          //   <div className={classes.inputgroup}>
          //     <label
          //       htmlFor={`bombPlanted_${index}`}
          //       style={{ color: "white" }}
          //     >
          //       Bomb Planted:
          //     </label>
          //     <input
          //       type="number"
          //       id={`bombPlanted_${index}`}
          //       className="form-control userField"
          //       value={user.bombPlanted || 0}
          //       onChange={(e) =>
          //         handlePointsChange(index, "bombPlanted", e.target.value)
          //       }
          //     />
          //   </div>
          //   <div className={classes.inputgroup}>
          //     <label
          //       htmlFor={`bombDiffused_${index}`}
          //       style={{ color: "white" }}
          //     >
          //       Bomb Diffused:
          //     </label>
          //     <input
          //       type="number"
          //       id={`bombDiffused_${index}`}
          //       className="form-control userField"
          //       value={user.bombDiffused || 0}
          //       onChange={(e) =>
          //         handlePointsChange(index, "bombDiffused", e.target.value)
          //       }
          //     />
          //   </div>
          // </div> */}
      </Modal.Body>
      <Modal.Footer style={{ background: "#1a1a1a" }}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ background: "#fd7e14", border: "none" }}
          onClick={handleSubmit}
        >
          Upload Result
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
