import Grid from "@mui/material/Grid";
import * as moment from "moment";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseTable from "../../components/UseTable/useTable";
import { headCells } from "./components/tblColumns";
import { TableRow, TableCell, Typography, Box } from "@mui/material";

import { getTransactions } from "./reducers";
import { getUser } from "../Profile/reducers";
import classes from "./index.module.scss";
import Loader from "../../components/Loader/Loader";

export default function Transactions() {
  const dispatch = useDispatch();
  const { TblContainer } = UseTable([], headCells);
  const transactions = useSelector(
    (state) => state.userTransactions.transactions
  );
  const loading = useSelector((state) => state.userTransactions.loading);

  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  return (
    <>
      {loading ? (
        <Loader color={1} />
      ) : (
        <>
          <Typography
            variant="h5"
            color="white"
            textAlign="left"
            sx={{ pt: 1, pb: 1 }}
          >
            Transaction History
          </Typography>
          <Box className={classes.roundTable}>
            <TblContainer>
              {transactions.map((row, index) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell align="left" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left" scope="row">
                      {row?.type}
                    </TableCell>
                    <TableCell align="center">{"$" + row?.amount}</TableCell>
                    <TableCell align="center">
                      {row?.createdAt
                        ? moment(row?.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : null}
                    </TableCell>
                    <TableCell align="center">{row?.status}</TableCell>
                  </TableRow>
                );
              })}
            </TblContainer>
          </Box>
        </>
      )}
    </>
  );
}
