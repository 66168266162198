import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const DEFAULT_INVENTORY = {
  data: [],
  limit: 0,
  total: 0,
  page: 0,
};

const slice = createSlice({
  name: "inventory",
  initialState: {
    loading: false,
    created: false,
    submitting: false,
    inventory: DEFAULT_INVENTORY,
    error: "",
    deleted: null,
  },
  reducers: {
    getInventory: (state, { payload }) => {
      state.loading = true;
    },
    getInventorySuccess: (state, { payload }) => {
      state.loading = false;
      state.inventory = {
        data: payload.result,
        page: parseInt(payload.allGame?.currentPage),
        total: parseInt(payload.allGame?.total),
      };
    },
    getInventoryFailed: (state, { payload }) => {
      NotificationManager.error(payload.message);
    },
    createInventory: (state, { payload }) => {
      state.loading = true;
      state.created = false;
      state.submitting = true;
    },
    createInventorySuccess: (state, { payload }) => {
      // const game = { ...payload.gameDetail };
      state.loading = false;
      state.created = true;
      state.submitting = false;
      // state.Inventory.data.push(game);
      state.inventory.total = state.inventory.total + 1;
      NotificationManager.success("Created successfully");
    },
    createInventoryFailed: (state, { payload }) => {
      state.loading = false;
      state.created = true;
      state.submitting = false;
      NotificationManager.error(payload.message);
    },
    updateInventory: (state, { payload }) => {
      state.loading = false;
    },
    updateInventorySuccess: (state, { payload }) => {
      state.loading = true;
      state.inventory.data = payload.result;
      NotificationManager.success("Updated successfully");
    },
    updateInventoryFailed: (state, { payload }) => {
      state.loading = false;
      NotificationManager.error(payload.message);
    },
    deleteInventory: (state, { payload }) => {
      state.loading = true;
    },
    deleteInventorySuccess: (state, { payload }) => {
      state.loading = false;
      state.inventory.data = payload.result;
      NotificationManager.success("Deleted successfully");
    },
    deleteInventoryFailed: (state, { payload }) => {
      NotificationManager.error(payload.message);
    },
    exportData: (state, { payload }) => {
      state.loading = true;
    },
    exportDataSuccess: (state, { payload }) => {
      state.loading = false;
      state.exporturl = payload.downloadPath;
      // NotificationManager.success("Request successfull");
    },
    exportDataFailed: (state, { payload }) => {
      NotificationManager.error(payload.message);
    },
  },
});

export const {
  getInventory,
  getInventorySuccess,
  getInventoryFailed,
  createInventory,
  createInventorySuccess,
  createInventoryFailed,
  updateInventory,
  updateInventorySuccess,
  updateInventoryFailed,
  deleteInventory,
  deleteInventorySuccess,
  deleteInventoryFailed,
  exportData,
  exportDataFailed,
  exportDataSuccess,
} = slice.actions;

export default slice.reducer;
