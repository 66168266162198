import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButton, Typography } from "@mui/material";

import classes from "./exportData.module.scss";

const BASE_URL = process.env.REACT_APP_BASE_URL + "/";

export default function ExportData({ exporturl }) {
  return (
    <>
      <a
        href={BASE_URL + exporturl}
        target="_blank"
        download
        className={classes.downloadButton}
      >
        Download Data
        <FileDownloadIcon
          style={{
            color: "#F26826",
            fontSize: "20px",
          }}
        />
      </a>

    </>
  );
}
