import { Modal, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { NotificationManager } from "react-notifications";
import { getUser } from "../../User/pages/Profile/reducers";

import "./index.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
const MyModal = styled(Modal)({
  "& .MuiBox-root": {
    background: "#1a1a1a",
  },
});

const DeleteButton = styled(Button)({
  backgroundColor: "#F26826 !important",
  margin: "4px",
  "&:hover": {
    backgroundColor: `#F26826 important`,
  },
});

const PaymentModal = ({ open, handleClose, text, type, data }) => {
  const disptach = useDispatch();
  const [formData, setFormData] = useState({
    fullName: null,
    email: null,
    amount: null,
    address: null,
  });

  const style = {
    background: "#1a1a1a",
    color: "whitesmoke",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "8px",
    boxShadow: 24,
    pt: 1,
    px: 1,
    pb: 1,
    textAlign: "center",
  };

  const withdrawAmountHandler = async () => {
    if (inputValidator()) {
      handleClose(false);
      const token = localStorage.getItem("userToken");
      const postLoad = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify(formData),
      };
      try {
        let res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/paypal/withdraw-request`,
          postLoad
        );
        await res.json().then((response) => {
          if (response.code == 400) {
            NotificationManager.error(
              response.message || response.error.message
            )
          } else {
            NotificationManager.success(
              "Action successful. Amount will be transfered to your connected account shortly!"
            );
            disptach(getUser());
          }
        });
      } catch (e) {
        NotificationManager.error("Something went wrong!");
      }
    } else {
      NotificationManager.error("Enter valid data please.");
    }
  };
  const addCreditHandler = async () => {
    if (inputValidator()) {
      handleClose(false);
      const token = localStorage.getItem("userToken");
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/paypal/add-payment`,
        { price: formData.amount },
        {
          headers: {
            Authorization: token
          }
        }
      ).then((res) => {
        let link = res.data.result.url;
        window.location.href = link;
      }
      );

      // console.log(response.data.result.url); // Log specific property of the response

      // Redirect if needed
      // window.location.href = response.data.result.url;

    } else {
      NotificationManager.error("Enter valid data please.");
    }
  };
  const inputValidator = () => {
    if (type === "cash" && formData.amount !== null) {
      return true;
    }
    if (
      formData.fullName &&
      formData.email &&
      formData.amount !== null &&
      formData.address
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <MyModal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: "400px" }}>
        <div className="row">
          <div className="col-12">
            <div className="card bg-dark text-white">
              <div className="card-header border-0">
                <div className="row">
                  <div className="col-md-12 pt-5">
                    <span className="h2">
                      {type === "withdraw" ? "Withdraw Amount" : "Add Credits"}
                    </span>
                  </div>
                </div>
                <div className="row">

                </div>
              </div>

              <div className="card-body" style={{ height: "auto" }}>
                <form>
                  {type === "withdraw" ? (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              id="cc-number"
                              type="text"
                              value={formData.fullName}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  fullName: e.target.value,
                                });
                              }}
                              className="input-lg form-control border-bottom cc-number bg-transparent border-light text-white"
                              autoComplete="cc-number"
                              placeholder="Full Name *"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              id="cc-number"
                              type="email"
                              value={formData.email}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  email: e.target.value,
                                });
                              }}
                              className="input-lg form-control border-bottom cc-number bg-transparent border-light text-white"
                              autoComplete="cc-number"
                              placeholder="Email *"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              value={formData.address}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  address: e.target.value,
                                });
                              }}
                              className="input-lg form-control border-bottom bg-transparent border-light text-white"
                              placeholder="Address *"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="form-group">
                            <input
                              id="cc-exp"
                              type="number"
                              value={formData.amount}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  amount: e.target.value,
                                });
                              }}
                              className="input-lg form-control border-bottom bg-transparent border-light cc-exp text-white"
                              autoComplete="cc-exp"
                              placeholder="Enter Amount"
                              required
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <div className="form-group">
                        <input
                          id="cc-exp"
                          type="number"
                          value={formData.amount}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              amount: e.target.value,
                            });
                          }}
                          className="input-lg form-control border-bottom bg-transparent border-light cc-exp text-white"
                          autoComplete="cc-exp"
                          placeholder="Enter Amount"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center pt-5">
                    <input
                      value={type == "withdraw" ? "Withdraw" : "Add "}
                      type="button"
                      onClick={() => {
                        type === "withdraw"
                          ? withdrawAmountHandler()
                          : addCreditHandler();
                      }}
                      className="btn btn-success btn-lg border-0"
                      style={{ fontSize: "0.8rem" }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

        {/* <p id="parent-modal-description">
          <DeleteButton variant="contained" onClick={() => handleClose(false)}>
            Close
          </DeleteButton>
        </p> */}
      </Box>
    </MyModal>
  );
};
export default PaymentModal;
