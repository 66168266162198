import { takeLatest, call, put } from "redux-saga/effects";
import {
  getMatchesResults,
  getMatchesResultsSuccess,
  getMatchesResultsFailed,
  deleteMatchesResult,
  deleteMatchesResultSuccess,
  deleteMatchesResultFailed,
  submitResult,
  submitResultSuccess,
  submitResultFailed,
  exportData,
  exportDataSuccess,
  exportDataFailed,
} from "./reducers";

import request from "../../../utils/apisauce";

function* getMatchesResultsSaga({ payload }) {
  let action;
  let url = "/api/admin/list-all-matches";
  try {
    // if (payload && payload["query"]) {
    //   url += `?query=${payload["query"]}`;
    // }
    if ("query" in payload) {
      url += `?query=${payload["query"]}`;
    }

    if (payload["pageNo"]) {
      url += `&pageNo=${payload["pageNo"]}`;
    }

    const response = yield call(request, "GET", url);
    action = getMatchesResultsSuccess(response);
  } catch (e) {
    action = getMatchesResultsFailed(e);
  }
  yield put(action);
}

function* submitResultSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      "POST",
      "/api/admin/matchResult",
      payload
    );
    action = submitResultSuccess(response);
  } catch (e) {
    action = submitResultFailed(e);
  }
  yield put(action);
}

function* deleteMatchesResultSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      "PATCH",
      "/api/admin/matchResult",
      payload
    );
    action = deleteMatchesResultSuccess(response);
  } catch (e) {
    action = deleteMatchesResultFailed(e);
  }
  yield put(action);
}

function* exportDataSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      "GET",
      "/api/admin/exportMatchResultData",
      payload
    );
    action = exportDataSuccess(response);
  } catch (e) {
    action = exportDataFailed(e);
  }
  yield put(action);
}
export default function* saga() {
  yield takeLatest(getMatchesResults, getMatchesResultsSaga);
  yield takeLatest(submitResult, submitResultSaga);
  yield takeLatest(deleteMatchesResult, deleteMatchesResultSaga);
  yield takeLatest(exportData, exportDataSaga);
}
