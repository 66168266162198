import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import axios from "axios";
import "./styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#bfbdbd",
  border: "2px solid #ff5200",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export default function PaymentModal() {
  const handlePay = async () => {
    try {
      const id = localStorage.getItem("user_id");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/paypal/subscribe`,
        { userId: id }
      );
      let link = response?.data?.result?.url;
      if (link !== undefined)
        window.location.href = link;
    } catch (error) {
      console.log("inside error", error);
    }
  };
  return (
    <div className="payContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <h2 style={{ marginTop: "50px", color: "#ff5200" }}>
          Online Battle Ground Payments
        </h2>
      </div>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        sx={style}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Please Pay $5 to Sign In
        </Typography>
        <Button
          style={{
            backgroundColor: "#ff5200",
            color: "#fff",
            width: "25%",
            marginTop: "20px",
          }}
          onClick={handlePay}
        >
          Pay Now
        </Button>
      </Box>
    </div>
  );
}
