import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DynamicButton from "../../components/dynamicButton/dynamicButton";
import InventoryCard from "../../components/inventoryCard/inventoryCard";
import axios from "axios";
import Loader from "../../components/Loader/Loader";

export default function MyInventory() {
  const [inventoryData, setInventoryData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const config = {
          headers: {
            Authorization: token,
          },
        };
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/inventory/list`,
          config
        );
        setLoading(false);
        setInventoryData(response.data.result);
      } catch (error) {
        setLoading(false);
        // Notification.error(error.response.message);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = () => {
    navigate("/user/community-market");
  };

  return (
    <>
      {loading ? (
        <Loader color={1} />
      ) : (
        <>
          {" "}
          <Box sx={{ marginBottom: "16px" }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                component="span"
                sx={{ color: "white", fontSize: 24, fontWeight: "bold" }}
              >
                MY INVENTORY
              </Typography>
              <Box>
                <DynamicButton
                  title="Community Market"
                  pl="20px"
                  pr="20px"
                  pt="5px"
                  pb="5px"
                  color={true}
                  clickHandler={handleButtonClick}
                />
              </Box>
            </Grid>
          </Box>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {inventoryData.map((item) => (
              <Grid item key={item._id} xs={12} sm={6} md={4} lg={3}>
                <InventoryCard
                  img={`${process.env.REACT_APP_BASE_URL}/${item.inventoryId?.link}`}
                  title={item?.inventoryId?.name}
                  date={item?.createdAt}
                  id={item._id}
                  forSale={item?.inventoryId?.forSale}
                  forRedeem={item?.inventoryId?.forRedeem}
                  item={item}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
