import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import trashIcon from "../../../assets/trash.png";
import trashIconMuted from "../../../assets/trash2.png";
import { NotificationManager } from "react-notifications";

export default function MyMarketHistoryTable() {
  const [sellHistory, setSellHistory] = useState([]);
  const token = localStorage.getItem("userToken");
  const [buyHistory, setBuyHistory] = useState([]);

  ///Buy History Api integration
  useEffect(() => {
    fetchBuyHistory();
  }, []);

  const fetchBuyHistory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/inventory/buyHistory`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setBuyHistory(response.data.result);
      })
      .catch((error) => {
        // Handle the error or display an error message
      });
  };

  // Sell history api integration
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/inventory/sellHistory`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setSellHistory(response.data.result);
      })
      .catch((error) => { });
  }, []);

  //delete api integration using axois
  const handleDeleteClick = (item) => {
    // Make a POST request to the delete API endpoint
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/inventory/delSellItem`, {
        id: item?._id,
      })
      .then((response) => {
        // Handle the successful response
        NotificationManager.success("Product Deleted");
      })
      .catch((error) => {
        // Handle the error
        NotificationManager.error("request UnsuccessFul");
        console.error("Error deleting item:", error);
      });
  };

  return (
    <>
      <TableContainer>
        <h6 style={{ color: "white" }}>My Sell History</h6>
        <Table
          sx={{
            marginTop: "5px",
            borderCollapse: "separate !important",
            borderSpacing: "0 8px !important",
            border: "none !important",
          }}
        >
          <TableHead sx={{ backgroundColor: "gray", border: "none" }}>
            <TableRow sx={{ mb: 3, borderBottom: "none" }}>
              <TableCell align="left" sx={{ color: "white" }}>
                Inventory
              </TableCell>
              <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                Listed On
              </TableCell>
              <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                Price
              </TableCell>
              <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                Status
              </TableCell>
              <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sellHistory.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  bgcolor: "#2e2d2d",
                  marginBottom: "8px",
                  border: "none ",
                  "&:hover": {
                    border: `1px solid #F26826 important`,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{ color: "white" }}
                >
                  {item?.userInventoryId?.inventoryId?.link.endsWith(".mp4") || item?.userInventoryId?.inventoryId?.link.endsWith('.avi') || item?.userInventoryId?.inventoryId?.link.endsWith('.mov') || item?.userInventoryId?.inventoryId?.link.endsWith('.wmv') ?
                    <video
                      src={`${process.env.REACT_APP_BASE_URL}/${item?.userInventoryId?.inventoryId?.link}`}
                      alt={item?.userInventoryId?.inventoryId?.link?.name}
                      controls
                      height="80" width="140"
                    /> : <img
                      src={`${process.env.REACT_APP_BASE_URL}/${item?.userInventoryId?.inventoryId?.link}`}
                      alt={item?.userInventoryId?.inventoryId?.link}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />}

                  {item?.userInventoryId?.inventoryId?.name}
                </TableCell>

                <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                  {new Date(item?.createdAt).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </TableCell>
                <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                  $ {item?.price}
                </TableCell>
                <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                  {item?.status}
                </TableCell>

                <TableCell align="left">
                  {item.status === "pending" ? (
                    <IconButton
                      sx={{ color: "white" }}
                      onClick={() => handleDeleteClick(item)}
                    >
                      {/* You can replace DeleteIcon with the trash icon */}

                      <img
                        src={trashIcon}
                        alt="Trash"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </IconButton>
                  ) : (
                    <img
                      src={trashIconMuted}
                      alt="Trash"
                      style={{ width: "24px", height: "24px" }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <h6 style={{ color: "white" }}>My Buy Orders</h6>
        {buyHistory.length === 0 ? (
          <p style={{ color: "white" }}>You don't have any buy orders.</p>
        ) : (
          <Table
            sx={{
              marginTop: "5px",
              borderCollapse: "separate !important",
              borderSpacing: "0 8px !important",
              border: "none !important",
            }}
          >
            <TableHead sx={{ backgroundColor: "gray", border: "none" }}>
              <TableRow sx={{ mb: 3, borderBottom: "none" }}>
                <TableCell align="left" sx={{ color: "white" }}>
                  Inventory
                </TableCell>
                <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                  Listed On
                </TableCell>
                <TableCell align="left" sx={{ color: "white" }}>
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buyHistory.map((item) => (
                <TableRow
                  key={item?._id}
                  sx={{
                    bgcolor: "#2e2d2d",
                    marginBottom: "8px",
                    border: "none ",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    sx={{ color: "white" }}
                  >
                    <img
                      src={` ${process.env.REACT_APP_BASE_URL}/${item?.inventoryId?.link}`}
                      alt={item?.inventoryId?.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />
                    {item?.inventoryId?.name}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "white", pr: 0 }}>
                    {new Date(item?.createdAt).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </TableCell>
                  <TableCell align="left" sx={{ color: "white" }}>
                    $ {item?.price}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}
