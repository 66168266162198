import { Formik } from "formik";
import React from "react";
import Modal from "../../components/topModal/topModal";
import FormSubmitButton from "../../../Admin/components/FormSubmitButton/formSubmitbutton";
import { allWordsCapitalize } from "../../../utils/apiutils";
import Input from "../../../Admin/components/Input/input";
import VideoUpload from "../videoUpload/videoUpload";
import _ from "lodash";
import SelectField from "../../../Admin/components/Input/selectField";
export default function uploadResultModal({
  id,
  open,
  name,
  teamId,
  handleClose,
  titleImage,
  formFieldName,
  handleUploadResult,
  scheduleData,
  flag,
  type,
}) {
  const matchId = _.filter(scheduleData, "matchId"); // [12, 14, 16, 18]
  const matchToMap = _.map(matchId, (key) => {
    return {
      gameName: key.matchId,
      _id: key.matchId,
    };
  });
  return (
    <Modal open={open} handleClose={handleClose} widthe={500}>
      <img
        alt=" "
        src={titleImage}
        height="200px"
        width="100%"
        style={{ borderRadius: "2%", marginTop: "5%", marginBottom: "5%" }}
      />
      <h4 style={{ color: "white" }}>{allWordsCapitalize(name)}</h4>

      {type ? (
        <Formik
          initialValues={{
            teamId: teamId,
            [formFieldName]: id,
            score: "",
            videoResult: "",
            matchId: id,
            mapScore1: "",
            mapScore2: "",
            mapScore3: "",
            mapScore4: "",
            mapScore5: "",
          }}
          onSubmit={(values) => {
            let formData = new FormData();
            teamId && formData.append(formFieldName, id);
            formData.append("teamId", teamId);
            formData.append("score", values.score);
            formData.append("result", values.result);
            formData.append("videoResult", values.videoResult);
            formData.append("matchId", values.matchId);
            formData.append("mapScore1", values.mapScore1);
            formData.append("mapScore2", values.mapScore2);
            formData.append("mapScore3", values.mapScore3);
            formData.append("mapScore4", values.mapScore4);
            formData.append("mapScore5", values.mapScore5);
            return handleUploadResult(formData);
          }}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <Input
                  placeholder="Score"
                  name="score"
                  type="text"
                  required={true}
                  onchange={handleChange}
                  value={values?.score}
                />
                <SelectField
                  placeholder="Result"
                  name="result"
                  required={true}
                  onchange={handleChange}
                  value={values?.result}
                  items={["Win", "Loss"]}
                />
                {/* {flag ? (
                <SelectField
                  placeholder="Match"
                  name="matchId"
                  required={true}
                  onchange={handleChange}
                  value={values?.matchId}
                  items={matchToMap}
                />
              ) : (
                <></>
              )} */}

                {/* New Map Score Input Fields */}
                <Input
                  placeholder="Map score 1"
                  name="mapScore1"
                  type="text"
                  required={true}
                  onchange={handleChange}
                  value={values.mapScore1}
                />
                <Input
                  placeholder="Map score 2"
                  name="mapScore2"
                  type="text"
                  required={true}
                  onchange={handleChange}
                  value={values.mapScore2}
                />
                <Input
                  placeholder="Map score 3"
                  name="mapScore3"
                  type="text"
                  required={true}
                  onchange={handleChange}
                  value={values.mapScore3}
                />
                <Input
                  placeholder="Map score 4"
                  name="mapScore4"
                  type="text"
                  required={true}
                  onchange={handleChange}
                  value={values.mapScore4}
                />
                <Input
                  placeholder="Map score 5"
                  name="mapScore5"
                  type="text"
                  required={true}
                  onchange={handleChange}
                  value={values.mapScore5}
                />
                {/* End of New Map Score Input Fields */}

                <VideoUpload
                  name="videoResult"
                  required
                  title="Video/Image Upload"
                  value={values.videoResult}
                  onchange={setFieldValue}
                />
                <FormSubmitButton
                  title={"Submit"}
                  clickHandler={function () {}}
                />
              </form>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={{
            teamId: teamId,
            [formFieldName]: id,
            score: "",
            videoResult: "",
            matchId: id,
          }}
          onSubmit={(values) => {
            let formData = new FormData();
            teamId && formData.append(formFieldName, id);
            formData.append("teamId", teamId);
            formData.append("score", values.score);
            formData.append("videoResult", values.videoResult);
            formData.append("matchId", values.matchId);
            return handleUploadResult(formData);
          }}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <Input
                  placeholder="Score"
                  name="score"
                  type="text"
                  required={true}
                  onchange={handleChange}
                  value={values.score}
                />
                {flag ? (
                  <SelectField
                    placeholder="Match"
                    name="matchId"
                    required={true}
                    onchange={handleChange}
                    value={values.matchId}
                    items={matchToMap}
                  />
                ) : (
                  <></>
                )}

                <VideoUpload
                  name="videoResult"
                  required
                  title="Video/Image Upload"
                  value={values.videoResult}
                  onchange={setFieldValue}
                />
                <FormSubmitButton
                  title={"Submit"}
                  clickHandler={function () {}}
                />
              </form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
}
