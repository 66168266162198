import { Modal, Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import classes from "./UserInventoryModal.module.scss";
import { Close, ClosedCaptionDisabledTwoTone } from "@mui/icons-material";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { exportData, getUserInventory } from "../../reducers";
import ExportData from "../../../ExportData/exportData";
const baseURL = process.env.REACT_APP_BASE_URL;

const MyModal = styled(Modal)({
  ".MuiBackdrop-root": {
    background: "transparent",
  },
  "& .MuiBox-root": {
    background: "#1a1a1a",
  },
});
const OkButton = styled(Button)(({ color }) => {
  return {
    backgroundColor: color ? "#4A4A4A" : "#F26826",
    padding: "7px 40px",
    color: "white",
    margin: "0px auto",
    "& :hover": {
      // backgroundColor: color ? "#4A4A4A" : "#F26826",
    },
  };
});

const UserInventory = (props) => {
  const dispatch = useDispatch();
  const inventoryList = useSelector((state) => state.userDetails.userInventory);
  const data = props?.data?.userInventory;
  const userId = props?.data?.userId;
  const [downloadPath, setDownloadPath] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL + "/";

  const handleExportData = async () => {
    let token = localStorage.getItem("adminToken");
    try {
      const response = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/admin/exportUserInventoryData?userId=${userId}`,

          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          NotificationManager.success(response.data.message);
          setDownloadPath(response.data.downloadPath);
        })
        .catch((error) => {
          NotificationManager.error(error.response.data.message);
        });
    } catch (error) {
      console.error("error  = ", error);
    }
  };

  ("");

  const handleDownload = () => { };
  const removeItemFromInventory = async (id) => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/Inventory/remove-from-user`,
          {
            inventoryId: id,
            userId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          NotificationManager.success(response.data.message);
          dispatch(getUserInventory({ _id: props?.data?.userId }));
        })
        .catch((error) => {
          NotificationManager.error(error.response.data.message);
        });

      console.log("response data = ", response.data);
    } catch (error) {
      // Handle the error condition
      console.error("error  = ", error);
    }
  };
  return (
    <>
      <div>
        <Box className={classes.wrapper}>
          {data?.length > 0 && (
            <div className={classes.scrollWrapper}>
              <Grid
                container
                pt={1}
                pb={3}
                className={classes.inventoryContainer}
              >
                <Grid className="far-apart-center">
                  {downloadPath === "" ? (
                    <button
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                        color: "#f26826",
                        fontSize: "20px",
                        padding: "14px",
                        paddingTop: "0",
                        display: "inlineBlock",
                      }}
                      onClick={handleExportData}
                    >
                      Export Data
                    </button>
                  ) : (
                    <a
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "18px",
                        padding: "14px",
                        display: "block",
                      }}
                      href={BASE_URL + downloadPath}
                      target="_blank"
                    >
                      Download
                    </a>
                  )}
                </Grid>
                {data?.map((item) => {
                  return (
                    <Grid item className={classes.inventoryItem}>
                      <div className={classes.imageWrapper}>
                        {item?.inventoryId?.link.endsWith(".mp4") || item?.inventoryId?.link.endsWith('.avi') || item?.inventoryId?.link.endsWith('.mov') || item?.inventoryId?.link.endsWith('.wmv') ? <video src={baseURL + "/" + item?.inventoryId?.link} height="100%" width="110" controls /> :
                          <img src={baseURL + "/" + item?.inventoryId?.link} />
                        }
                      </div>
                      <div className={classes.title}>
                        <h4>{item?.inventoryId?.name}</h4>
                      </div>
                      <div>
                        <OkButton
                          type="button"
                          onClick={() =>
                            removeItemFromInventory(item?.inventoryId?._id)
                          }
                        >
                          {"Remove"}
                        </OkButton>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          )}
          {data?.length <= 0 && (
            <Grid container pt={1} pb={3} className={classes.notFoundContainer}>
              <h4>No Data Found. Kindly create inventory list.</h4>
            </Grid>
          )}
        </Box>
      </div>
    </>
  );
};

export default UserInventory;
