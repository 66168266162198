import { takeLatest, call, put } from "redux-saga/effects";
import {
  sendSupportMessage,
  sendSupportMessageSuccess,
  sendSupportMessageFailed,
} from "./reducers";

import request from "../../../utils/apisauce";

function* sendSupportMessageSaga({ payload }) {
  let action;
  try {
    const response = yield call(request, "POST", "/api/reportIssue", payload);
    action = sendSupportMessageSuccess(response);
  } catch (e) {
    action = sendSupportMessageFailed(e);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(sendSupportMessage, sendSupportMessageSaga);
}
