export const headCells = [
  {
    id: "index",
    numeric: false,
    disablePadding: true,
    label: "No",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "datetime",
    numeric: true,
    disablePadding: false,
    label: "Date&Time",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  }
];
