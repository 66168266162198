import React from "react";
import { Grid, Typography } from "@mui/material";

import WinOrLossCard from "../../../../components/winOrLossCard/winOrLossCard";
import GeneralText from "../../../../components/generalText/generalText";

const BASE_URL = process.env.REACT_APP_BASE_URL + "/";

export default function PlayedTournaments({ tournaments }) {
  return (
    // <Grid container>
    //   <Grid
    //     item
    //     // md={12}
    //     // lg={3}
    //     // sm={12}
    //     sx={{
    //       padding: "30px",
    //       border: "1px solid #707070",
    //       borderRadius: "8px",
    //     }}
    //   >
    //     <Grid container spacing={1}>
    //       {tournaments.length ? (
    //         tournaments.map((x, i) => (
    //           <Grid item key={i} md={6} lg={4} xl={4} sm={}>
    //             <WinOrLossCard
    //               key={i}
    //               link={"/user/tournament/" + x._id}
    //               img={
    //                 x.tournamentTitleImage
    //                   ? BASE_URL + x.tournamentTitleImage
    //                   : ""
    //               }
    //               name={x.tournamentName}
    //               status={x?.status}
    //               date={x.tournamentDate}
    //             />
    //           </Grid>
    //         ))
    //       ) : (
    //         <GeneralText text="No Tournaments Found!" />
    //       )}
    //     </Grid>
    //   </Grid>
    // </Grid>
    <Grid
      container
      sx={{
        padding: "30px",
        border: "1px solid #707070",
        borderRadius: "8px",
      }}
    >
      {tournaments.length ? (
        tournaments.map((x, i) => (
          <Grid
            item
            key={i}
            md={6}
            lg={6}
            xl={4}
            sm={6}
            sx={{ marginBottom: "10px" }}
          >
            <WinOrLossCard
              key={i}
              link={"/user/tournament/" + x._id}
              img={
                x.tournamentTitleImage ? BASE_URL + x.tournamentTitleImage : ""
              }
              name={x.tournamentName}
              status={x?.status}
              date={x.tournamentDate}
            />
          </Grid>
        ))
      ) : (
        <GeneralText text="No Tournaments Found!" />
      )}
    </Grid>
  );
}
