import friendImg from "../../../image/3.png";
import ResultDiv from "../components/ResultDiv/result-div";
const baseURL = process.env.REACT_APP_BASE_URL;

const LadderDetailsRow = (props) => {
  return (
    <tr>
      <th scope="row">
        <img
          src={
            props.data.ladderTitleImage !== ""
              ? baseURL + "/" + props.data.ladderTitleImage
              : friendImg
          }
          alt=""
          style={{
            width: "32px",
            height: "31px",
          }}
        />
        {props.data.ladderName}
      </th>
      <td>{props.data.gameToPlay}</td>
      <td>$ {props.data.prize}</td>
      <td>
        <ResultDiv result={props.data.ladderType} />
        <div className="more_info">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle categories_button "
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              More Info
            </button>
            <ul
              className="dropdown-menu  dropdown-menu-info"
              aria-labelledby="dropdownMenu2"
            >
              <li>
                User Team:<span>{props.data.teamViewName}</span>
              </li>
              <li>
                Start Date & Time:
                <span>{props.data.startingDateAndTime}</span>
              </li>
              <li>
                End Date & Time:
                <span>{props.data.endingDateAndTime}</span>
              </li>
            </ul>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default LadderDetailsRow;
