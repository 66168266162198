import { Modal, Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { Formik } from "formik";

import Input from "../../../../components/Input/input";
import FormSubmitButton from "../../../../components/FormSubmitButton/formSubmitbutton";
import ImageUpload from "../../../../components/ImageUpload/imageUpload";
import SelectMultiField from "../../../../components/Input/selectMultiField";
import SelectField from "../../../../components/Input/selectField";
import classes from "./addEditModal.module.scss";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const MyModal = styled(Modal)({
  "& .MuiBox-root": {
    background: "#1a1a1a",
    width: "635px",
    paddingTop: "5%",
    height: "95vh",
    overflow: "auto",
  },
  "& MuiGridItem-root": {
    margin: "auto",
  },
});

const GeneralModal = ({
  open,
  handleClose,
  handleEditInventory,
  formDataObj,
}) => {
  const style = {
    background: "#1a1a1a",
    color: "whitesmoke",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "635px  !important",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: "center",
  };

  const [forSale, setforSale] = useState(formDataObj.forSale);
  const [forRedeem, setforRedeem] = useState(formDataObj.forRedeem);
  const [isAvailable, setIsAvailable] = useState(true);

  const handleChangeForSale = (event) => {
    setforSale(event.target.value);
  };

  const handleChangeForRedeem = (event) => {
    setforRedeem(event.target.value);
  };
  return (
    <MyModal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <Formik
          initialValues={formDataObj}
          onSubmit={(values) => {
            let formData = new FormData();
            formData.append("_id", values._id);
            formData.append("inventory", values.inventory);
            formData.append("name", values.name);
            formData.append("forSale", forSale);
            formData.append("forRedeem", forRedeem);
            formData.append("isAvailable", isAvailable);
            return handleEditInventory(formData);
          }}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            setFieldTouched,
          }) => {
            return (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <Grid container spacing={2} px={12}>
                  <Grid item className={classes.gridItem} xs={12} md={12}>
                    <h5 className={classes.picuploadHeading}>
                      Inventory photo
                    </h5>
                    <ImageUpload
                      name="inventory"
                      required={true}
                      onchange={setFieldValue}
                      value={values.inventory}
                      default
                      setFieldTouched={setFieldTouched}
                    />
                  </Grid>
                  <Grid item xs={12} mt={3} md={12}>
                    <Input
                      placeholder="Inventory name"
                      name="name"
                      type="text"
                      required={true}
                      onchange={handleChange}
                      value={values.name}
                    />
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Grid item xs={12} mt={3} md={12}>
                      <InputLabel id="demo-simple-select-label">
                        For Sale
                      </InputLabel>
                      <Select
                        style={{ background: "#dbdbdb" }}
                        value={forSale}
                        onChange={handleChangeForSale}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} mt={3} md={12}>
                      <InputLabel>For Redeem</InputLabel>
                      <Select
                        style={{ background: "#dbdbdb" }}
                        value={forRedeem}
                        label="Redeem"
                        onChange={handleChangeForRedeem}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </Grid>
                  </div>
                </Grid>

                <FormSubmitButton
                  title={"Update"}
                  clickHandler={function () {}}
                />
              </form>
            );
          }}
        </Formik>
      </Box>
    </MyModal>
  );
};
export default GeneralModal;
