import * as React from "react";
import { useState, useEffect } from "react";

import {
  Grid,
  Box,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { getTransactions } from "./reducers";
import { dateTimeFormate, tooltipTrim } from "../../../utils/apiutils";
import { headCells } from "./components/tableColumns";
import useTable from "../../components/UseTable/useTable";
import GeneralText from "../../../User/components/generalText/generalText";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function EnhancedTable() {
  const [searchedVal, setSearchedVal] = useState("");
  const [deleteModel, setDeleteModal] = React.useState(false);
  const [addModal, setAddModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions);
  const { data, total, page } = transactions.transactions;
  const { TblContainer, TblHead, TblToolbar, ComponentContainer, Pagination } =
    useTable(data, headCells);

  let pageCpy = page;

  useEffect(async () => {
    //dispatch(getGames({ query: "" }));
    dispatch(getTransactions({ pageNo: 1 }));
  }, []);
  useEffect(() => {
    window.scroll(window.scrollX, window.scrollY);
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    let newPageCpy = newPage;
    //dispatch(getGames({ query: "", pageNo: ++newPageCpy }));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const approvalClickHandler = (row) => {
    
  }

  const searchHandler = () => {

  }

  return (
    <ComponentContainer>
      <Typography sx={{ fontWeight: "bold", color: "white", mt: 2, mb: 2 }}>
        Transactions
      </Typography>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TblToolbar
          numSelected={selected.length}
          setAddModal={setAddModal}
          setDeleteModal={setDeleteModal}
          searchHandler={searchHandler}
          value={searchedVal}
          placeholder="'Transaction' search"
          deleteIcon={false}
          addIcon={false}
        />
        {data?.length ? (
          <TblContainer>
            <TblHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data?.length}
            />
            <TableBody>
              {data &&
                data?.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#4A4A4A" : "#282828",
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row._id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell id={labelId} align="left">
                        {tooltipTrim(row?.fullName, 20)}
                      </TableCell>
                      <TableCell align="center">
                        {tooltipTrim(row?.type, 15)}
                      </TableCell>
                      <TableCell align="center">
                        {tooltipTrim(row?.email, 15)}
                      </TableCell>
                      <TableCell align="center">
                        {tooltipTrim(row?.amount + "$", 15)}
                      </TableCell>
                      <TableCell align="center">
                        {tooltipTrim(row?.status, 15)}
                      </TableCell>
                      <TableCell align="center">
                        {tooltipTrim(row?.address, 15)}
                      </TableCell>
                      {/* <TableCell align="center">
                        {dateTimeFormate(row.uploadedDate)}
                      </TableCell> */}
                      <TableCell align="center">
                        <IconButton onClick={() => approvalClickHandler(row)}>
                          Approve
                          {/* <EditIcon style={{ color: "#90caf9" }} /> */}
                        </IconButton>
                        {/* <IconButton onClick={() => editClickHandler(row)}>
                          <EditIcon style={{ color: "#90caf9" }} />
                        </IconButton> */}
                        {/* <IconButton
                          onClick={(e) => singleGameDeleteHandler(e, row._id)}
                        >
                          <DeleteIcon style={{ color: "#DF4646" }} />
                        </IconButton> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </TblContainer>
        ) : (
          <Grid
            container
            sx={{ background: "#282828", width: "100%", height: "100px" }}
          >
            <GeneralText text="No Transactions Found!" />
          </Grid>
        )}

        <TablePagination
          style={{ display: "none" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Pagination
          data={total}
          page={--pageCpy}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </ComponentContainer>
  );
}
