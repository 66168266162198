import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const slice = createSlice({
  name: "userTransactions",
  initialState: {
    loading: false,
    transactions: [],
    error: "",
  },
  reducers: {
    getTransactions: (state, { payload }) => {
      state.loading = true;
    },
    getTransactionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions = payload;
    },
    getTransactionsFailed: (state, { payload }) => {
      state.loading = false;
      NotificationManager.error(payload.message);
    },
  }  
});

export const {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
} = slice.actions;

export default slice.reducer;
