import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const slice = createSlice({
  name: "userSupport",
  initialState: {
    loading: false,
    error: "",
  },
  reducers: {
    sendSupportMessage: (state, { payload }) => {
      state.loading = true;
    },
    sendSupportMessageSuccess: (state, { payload }) => {
      state.loading = false;
      NotificationManager.success("Your message was sent successfully!");
    },
    sendSupportMessageFailed: (state, { payload }) => {
      state.loading = false;
      NotificationManager.error(payload.message);
    },
  }  
});

export const {
  sendSupportMessage,
  sendSupportMessageSuccess,
  sendSupportMessageFailed
} = slice.actions;

export default slice.reducer;
