import DynamicButton from "../../components/dynamicButton/dynamicButton";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUser } from "../Profile/reducers";
import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";

const TransactionSuccess = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("userId");
  const paymentId = new URLSearchParams(search).get("paymentId");
  const token = new URLSearchParams(search).get("token");
  const PayerID = new URLSearchParams(search).get("PayerID");

  useEffect(async () => {
    if (userId && paymentId && token && PayerID) {
      const getLoad = {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
      };
      try {
        const amount = localStorage.getItem("amount");
        let res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/withdraw/success?userId=${userId}&paymentId=${paymentId}&token=${token}&amount=${amount}&PayerID=${PayerID}`,
          getLoad
        );
        const response = await res.json();
        if (response.code === 403) {
          NotificationManager.error(response.message);
          navigate("/user/transactions");
        } else {
          dispatch(getUser());
          localStorage.removeItem("amount");
          NotificationManager.success("Successful transaction!");
        }
      } catch (e) {}
    }
  });

  const transactionsRedirectHandler = () => {
    navigate("/user/transactions");
  };

  return (
    <>
      <Typography
        component="span"
        sx={{ color: "green", fontSize: 24, fontWeight: "bold" }}
      >
        The transaction was successful...
      </Typography>
      <Box>
        <DynamicButton
          title="See Transactions"
          clickHandler={() => transactionsRedirectHandler()}
          pl="20px"
          pr="20px"
          pt="5px"
          pb="5px"
          color={true}
        />
      </Box>
    </>
  );
};

export default TransactionSuccess;
