const UserDetailsNav = (props) => {
  return (
    <ul className="nav nav-tabs">
      <li className="active ">
        <a data-toggle="tab" onClick={() => props.navStateHandler("info")}>
          Info
        </a>
      </li>
      <li>
        <a
          data-toggle="tab"
          onClick={() => props.navStateHandler("transaction")}
        >
          Transaction
        </a>
      </li>
      <li>
        <a data-toggle="tab" onClick={() => props.navStateHandler("history")}>
          Match History
        </a>
      </li>
      <li>
        <a data-toggle="tab" onClick={() => props.navStateHandler("inventory")}>
          Inventory
        </a>
      </li>
    </ul>
  );
};

export default UserDetailsNav;
