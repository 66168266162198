import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

export const DEFAULT_USER_DETAILS = {
  isLoading: false,
  userInfo: {
    profileImage: "",
    fullName: "",
    userName: "",
    credits: "",
    franchiseMode: "",
    franchiseName: "",
    franchiseOwnerName: "",
    franchiseTeams: 0,
    friends: 0,
    generalTeams: 0,
    totalTeams: 0,
  },
  userMatchHistory: {
    OneToOneMatches: [
      {
        matchTitleImage: "", //"Uploads/MatchImages/matchTitleImage-1669291307126.jpeg",
        status: "",
        winningUser: "",
        _id: "",
        matchName: "",
        gameToPlay: "",
        challengeTo: "",
        prize: 0,
        updatedAt: "",
        matchResult: "",
      },
    ],
    ladderDetails: [
      {
        ladderTitleImage: "",
        _id: "",
        ladderName: "",
        gameToPlay: "",
        prize: 0,
        ladderType: "",
        startingDateAndTime: "",
        endingDateAndTime: "",
        teamViewName: "",
      },
    ],
    tournamentDetails: [
      {
        tournamentTitleImage: "",
        tournamentType: "",
        winningTeam: "",
        _id: "",
        tournamentName: "",
        gameToPlay: "",
        entryFee: 5,
        prize: 10,
        startingDateAndTime: "",
        teamViewName: "",
        tournamentResult: "",
      },
    ],
    gpLeagueDetails: [
      {
        ladderTitleImage: "",
        _id: "",
        leagueName: "",
        gameToPlay: "",
        prize: 0,
        startingDateAndTime: "",
        endingDateAndTime: "",
        teamViewName: "",
        teamId: "",
        leagueResult: "",
      },
    ],
    fantasyLeagueDetails: [
      {
        flTitleImage: "",
        flType: "",
        leagueName: "",
        winnerId: "",
        winner: "",
        _id: "",
        flName: "",
        draftDateAndTime: "",
        gameName: "",
        flTeamName: "",
        flTeamId: "",
        flResult: "",
      },
    ],
  },
  userTransaction: {
    transactionDetail: [{}],
  },
  userInventory: [{}],
};

const slice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    userInfo: DEFAULT_USER_DETAILS.userInfo,
    userMatchHistory: DEFAULT_USER_DETAILS.userMatchHistory,
    userTransaction: DEFAULT_USER_DETAILS.userTransaction,
    userInventory: DEFAULT_USER_DETAILS.userInventory,
  },
  reducers: {
    getUserDetailsInfo: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserDetailsInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userInfo = { ...payload };
    },
    getUserDetailsFailed: (state, { payload }) => {
      state.isLoading = false;
      NotificationManager.error(payload.message);
    },

    getUserInventory: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserInventorySuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userInventory = payload;
    },
    getUserInventoryFailed: (state, { payload }) => {
      state.isLoading = false;
      NotificationManager.error(payload.message);
    },
    getUserOneToOneMatchesDetails: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserOneToOneMatchesDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userMatchHistory.OneToOneMatches = payload.matchesDetail;
    },
    getUserTournamentDetails: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserTournamentDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;

      state.userMatchHistory.tournamentDetails = payload.tournamentsDetail;
    },
    getUserGpLeagueDetails: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserGpLeagueDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userMatchHistory.gpLeagueDetails = payload.gpLeaguesDetail;
    },
    getUserLadderDetails: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserLadderDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userMatchHistory.ladderDetails = payload.laddersDetail;
    },
    getUserFantasyLeagueDetails: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserFantasyLeagueDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userMatchHistory.fantasyLeagueDetails = payload.flLeaguesDetail;
    },
    getUserTransactionDetails: (state, { payload }) => {
      state.isLoading = true;
    },
    getUserTransactionDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userTransaction.transactionDetail = payload.transactionDetail;
    },
  },
});

export const {
  getUserDetailsInfo,
  getUserDetailsFailed,
  getUserDetailsInfoSuccess,
  getUserInventory,
  getUserInventorySuccess,
  getUserInventoryFailed,
  getUserOneToOneMatchesDetails,
  getUserOneToOneMatchesDetailsSuccess,
  getUserTransactionDetails,
  getUserTransactionDetailsSuccess,
  getUserFantasyLeagueDetails,
  getUserFantasyLeagueDetailsSuccess,
  getUserGpLeagueDetails,
  getUserGpLeagueDetailsSuccess,
  getUserLadderDetails,
  getUserLadderDetailsSuccess,
  getUserTournamentDetails,
  getUserTournamentDetailsSuccess,
} = slice.actions;

export default slice.reducer;
