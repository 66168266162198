import { useParams, useNavigate } from "react-router";
import {
  Grid,
  IconButton,
  Typography,
  Box,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
} from "@mui/material";

import moment from "moment";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import teamSizeImg from "../../../assets/Group 168(1).svg";
import registeredImg from "../../../assets/Mask Group 8.svg";
import bracketImg from "../../../assets/Mask Group 9.svg";
import MiniDetail from "../../components/miniDetail/miniDetail";
import pricePoolImg from "../../../assets/Group 16.svg";
import tourBackImg from "../../../assets/tourback.png";
import PrimaryButton from "../../components/primaryButton/primaryButton";
import {
  getTeams,
  getLadder,
  joinLadder,
  addResult,
  getTotalWarLadder,
  addTotalWarLadderResult,
} from "./reducers";
import JoinTeamModal from "../../components/joinTeamModal/joinTeamModal";
import UseTable from "../../components/UseTable/useTable";
import UploadResultModal from "../../components/uploadResultModal/uploadResultModal";
import { headCells } from "./components/tblCells";
import { capitalize } from "../../../utils/apisauce";
import classes from "./ladderProfile.module.scss";
import {
  allWordsCapitalize,
  isDatePassed,
  timeFormate,
  tooltipTrim,
} from "../../../utils/apiutils";
import TotalWarLadders from "./components/totalWarLadders";
import GeneralText from "../../components/generalText/generalText";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL + "/";
const TabsBeforejoining = ["Ladder"];
const TabsAfterjoining = ["Ladder", "Recent Activity"];

export default function LadderProfile() {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [storeValues, setStoreValues] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState("Ladder");
  const [joinTour, setJoinTour] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [warLaderResultModal, setWarLaderResultModal] = useState(false);
  const [showRecentTab, setShowRecentTab] = useState(false);

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const handleConfirmYes = () => {
    dispatch(joinLadder(storeValues));
    setJoinTour(false);
    setConfirmModalOpen(false);
  };

  const { ladder, teams, totalWarLadders } = useSelector(
    (state) => state.userLadderProfile
  );
  const { ladderTeamRecord } = ladder;

  const BackImg = ladder.ladderTitleImage
    ? BASE_URL + ladder.ladderTitleImage
    : tourBackImg;

  const { TblContainer } = UseTable(ladder, headCells);

  useEffect(() => {
    dispatch(getLadder({ id: id }));
  }, []);
  useEffect(() => {
    if (tab === "Ladder Standing") {
      dispatch(getTotalWarLadder({ ladderId: id }));
    }
  }, [tab]);

  const handleJoinTour = (values) => {
    setConfirmModalOpen(true);
    setStoreValues(values);
  };
  const setJoinTourHanlder = () => {
    setJoinTour(true);
    dispatch(getTeams());
  };
  const handleUploadResult = (values) => {
    if (warLaderResultModal) {
      dispatch(addTotalWarLadderResult(values));
    } else {
      dispatch(addResult(values));
    }
    setResultModal(false);
    setWarLaderResultModal(false);
  };
  const handleClose = () => {
    setJoinTour(false);
    setResultModal(false);
    setWarLaderResultModal(false);
  };

  function getRelativetime(timestamp) {
    return moment(timestamp).format("Y-MM-D h:m:s a");
  }

  const [tournamentActivity, setTournamentActivity] = useState([]);
  const fetchData = async () => {
    const token = localStorage.getItem("userToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/activity/list?ladderId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // Handle the response data as needed
      setTournamentActivity(response.data.activities);
    } catch (error) {
      // Handle the error condition
    }
  };

  useEffect(() => {
    if (ladder.ladderJoined) {
      setShowRecentTab(true);
      fetchData();
    } else {
      setShowRecentTab(false); // Reset showRecentTab when user joins a ladder
    }
  }, [ladder.ladderJoined]);

  return (
    <>
      <JoinTeamModal
        id={id}
        teams={teams}
        open={joinTour}
        name={ladder.ladderName}
        titleImage={BackImg}
        formFieldName={"ladderId"}
        handleClose={handleClose}
        handleJoinTour={handleJoinTour}
      />

      <Dialog
        open={confirmModalOpen}
        onClose={handleCloseConfirmModal}
        sx={{
          "& .MuiDialog-paper": {
            width: "438px",
            height: "199px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#1A1A1A",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
            fontSize: "18px",
          }}
        >
          Are you sure you want to join this ladder?
        </DialogTitle>

        <DialogActions
          sx={{
            backgroundColor: "#1A1A1A",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "70px",
            gap: "10px",
          }}
        >
          <Button
            onClick={handleCloseConfirmModal}
            sx={{
              color: "white",
              bgcolor: "#F26826",
            }}
          >
            No
          </Button>
          <Button
            onClick={handleConfirmYes}
            sx={{
              color: "white",
              bgcolor: "#F26826",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <UploadResultModal
        id={id}
        open={resultModal || warLaderResultModal}
        name={ladder.ladderName}
        titleImage={BackImg}
        teamId={ladder.teamId}
        formFieldName={"ladderId"}
        handleClose={handleClose}
        handleUploadResult={handleUploadResult}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid
            item
            md={12}
            className={classes.topSection}
            style={{
              background: `url('${BackImg}')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <IconButton
              onClick={() => navigate(-1)}
              className={classes.backChevron}
            >
              <ChevronLeftIcon sx={{ color: "#F26826", pb: "3px" }} />
            </IconButton>
            <Grid container className={classes.ladderProfileImg}>
              <Grid item md={6}>
                <Box className={classes.gameName}>
                  {ladder.ladderStartDate} <br />
                  STARTING AT
                  <span className={classes.timetext}>
                    {ladder.ladderStartTime} UTC
                  </span>
                </Box>
              </Grid>
              <Grid item md={6} className={classes.joinButton}>
                {!ladder.ladderJoined ? (
                  <PrimaryButton
                    title="Join Ladder"
                    clickHandler={setJoinTourHanlder}
                  />
                ) : (
                  !isDatePassed(ladder.ladderEndDate) && (
                    <PrimaryButton
                      title="Add Result"
                      clickHandler={() => setResultModal(true)}
                    />
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.centerBar}>
            <Typography component="p" className={classes.ladderName}>
              {capitalize(ladder?.ladderName)}
            </Typography>
            {ladder?.description &&
              <Grid item md={12} className={classes.centerBar}>
                <Typography component="p" style={{
                  fontSize: "20px", color: "#fff", fontWeight: "500"
                }}>
                  Description
                </Typography>

                <Typography component="p" style={{ fontSize: "16px", color: "#fff" }}>
                  {ladder?.description}
                </Typography>
              </Grid>}

            <Box pb={5} pt={2}>
              {showRecentTab
                ? TabsAfterjoining.map((x) => (
                  <Typography
                    key={x}
                    component="span"
                    onClick={() => setTab(x)}
                    className={
                      tab === x ? classes.styledTab : classes.tabType
                    }
                  >
                    {allWordsCapitalize(x)}
                  </Typography>
                ))
                : TabsBeforejoining.map((x) => (
                  <Typography
                    key={x}
                    component="span"
                    onClick={() => setTab(x)}
                    className={
                      tab === x ? classes.styledTab : classes.tabType
                    }
                  >
                    {allWordsCapitalize(x)}
                  </Typography>
                ))}
            </Box>
            {/* {tab === "Ladder Standing" && (
              <TotalWarLadders
                teamId={ladder?.teamId}
                matches={totalWarLadders?.matches}
                matchResults={totalWarLadders?.matchResults}
                resultSubmit={totalWarLadders?.resultSubmit}
                setWarLaderResultModal={setWarLaderResultModal}
              />
            )} */}
            {tab === "Ladder" && (
              <Box className={classes.miniDetailsBar}>
                <Grid container>
                  <Grid item md={12 / 5}>
                    <MiniDetail
                      logo={pricePoolImg}
                      title={"Prize Pool"}
                      value={ladder.prize}
                    />
                  </Grid>
                  <Grid item md={12 / 5}>
                    <MiniDetail
                      logo={teamSizeImg}
                      title={"Team Size"}
                      value={ladder.teamSize}
                    />
                  </Grid>
                  <Grid item md={12 / 5}>
                    <MiniDetail
                      logo={pricePoolImg}
                      title={"Entry Fee"}
                      value={ladder.entryFee}
                    />
                  </Grid>
                  <Grid item md={12 / 5}>
                    <MiniDetail
                      logo={registeredImg}
                      title={"Registered"}
                      value={ladder.registered}
                    />
                  </Grid>
                  <Grid item md={12 / 5}>
                    <MiniDetail
                      logo={bracketImg}
                      title={"Total teams"}
                      value={ladder.totalTeams}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>


          {tab === "Ladder" &&
            (ladderTeamRecord?.length ? (
              <TblContainer>
                {ladderTeamRecord.map((row) => {
                  const streakResClr =
                    row.streakResult &&
                      row.streakResult[row.streakResult?.length - 1] === "W"
                      ? "#77F226"
                      : "#F22626";
                  return (
                    <TableRow className={classes.tblrow} hover key={row._id}>
                      <TableCell align="left" scope="row" padding="none">
                        {row?.standing}
                      </TableCell>
                      <TableCell align="center">
                        {tooltipTrim(row?.teamViewName, 20)}
                      </TableCell>
                      <TableCell align="center" style={{ color: "#77F226" }}>
                        {row?.winCount}
                      </TableCell>
                      <TableCell align="center" style={{ color: "#F22626" }}>
                        {row?.lossCount}
                      </TableCell>
                      <TableCell align="center">
                        {row.winPercentage && !row.winPercentage === "NaN%"
                          ? row.winPercentage
                          : "--"}
                      </TableCell>
                      <TableCell align="center" style={{ color: streakResClr }}>
                        {row.streakResult ? row.streakResult : "--"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TblContainer>
            ) : (
              <GeneralText text="No Table Records Found!" height="200px" />
            ))}
          {tab === "Recent Activity" && showRecentTab && (
            // Content for Recent Activity tab goes here
            <>
              {tournamentActivity?.length ? (
                <Grid container mt={2} className={classes.bottomBar}>
                  {tournamentActivity.map((activity, index) => (
                    <>
                      <Grid item md={7} key={activity._id}>
                        <Grid container>
                          <Grid
                            item
                            md={12 / 2}
                            lg={12 / 2}
                            xl={12 / 2}
                            xs={12}
                          >
                            <p>{activity?.text}</p>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item md={1}></Grid>
                      <Grid item md={4} className={classes.winningBox}>
                        <span>{getRelativetime(activity?.createdAt)}</span>
                      </Grid>
                    </>
                  ))}
                </Grid>
              ) : (
                <GeneralText text="No Recent Activity Found!" height="200px" />
              )}
            </>
          )}
        </Grid>
      </Box >
    </>
  );
}
