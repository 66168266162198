import React, { useState, useEffect } from "react";

import { SingleEliminationBracket, DoubleEliminationBracket, Match, MATCH_STATES, SVGViewer } from '@g-loot/react-tournament-brackets';
import { MatchData } from './match'
import classes from "./schedule.module.scss";
import {
  Box,
} from "@mui/material";
export default function Schedule(props) {

  useEffect(async () => {
    // setTimeout(() => {
    //   setShowmore(true)


    // setScheduleData(data)

  }, [])

  const finalWidth = Math.max(2020, 500);
  const finalHeight = Math.max(1020, 2000);

  return (
    <>
      {/* {singleRound?.matches?.length ? (
        // <RoundTable
        //   matches={singleRound?.matches}
        //   roundNo={roundNo}
        //   leagueScheduleData={data}
        // />
        <GeneralText text="No Schedule Found. Schedule will be auto generated on completion of all teams" />

      ) : (
        <GeneralText text="No Schedule Found. Schedule will be auto generated on completion of all teams" />
      )} */}
      <Box mt={2} className={classes.roundTable}>

        {/* <p>{matches.data.length}</p> */}
        {/* {matches?.data?.length ? (
          <SingleEliminationBracket
            matches={MatchData.leagueScheduleData}
            matchComponent={Match}
            svgWrapper={({ children, ...props }) => (
              <SVGViewer width={finalWidth}
                height={finalHeight} {...props} >
                {children}
              </SVGViewer>
            )}
          />
        ) : (
          <Grid
            container
            marginY={4}
            justifyContent="end"
            alignItems="center"
            sx={{ fontSize: "20px", color: "#484848" }}
          >
            <span>No Rounds Found! </span>
          </Grid>
        )
        } */}

        <SingleEliminationBracket
          matches={props.scheduleData}
          matchComponent={Match}
          svgWrapper={({ children, ...props }) => (
            <SVGViewer width={finalWidth}
              height={finalHeight} {...props} >
              {children}
            </SVGViewer>
          )}
        />
      </Box >
    </>
  );
}