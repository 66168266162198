

const UserDetailsInfo = (props) => {
  console.log({ props })
  return (
    <div id="info" className="">
      <div className="list row m-0">
        <div className="friend col-12 pt-3 p-0">
          <div className="text row m-0">
            <h4 className=" p-0 col-8">Friends</h4>
            <h6 className=" p-0 col-4">{props.data.friends}</h6>
          </div>
        </div>

        <div className="teams col-12 pt-3 p-0">
          <div className="text row m-0">
            <div className="dropdown col-8 p-0">
              <button
                className="btn btn-secondary dropdown-toggle p-0 teams-list btn-sm"
                type="button"
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Teams
              </button>
              <ul
                className="dropdown-menu dropdown-menu-list dropdown-list"
                aria-labelledby="dropdownMenu2"
              >
                <li>
                  <div className="text row pt-1 px-3">
                    <h4 className="col-10 p-0">Grand Prix</h4>
                    <h6 className="col-2 p-0">{props.data.franchiseTeams}</h6>
                  </div>
                </li>

              </ul>
            </div>

            <h6 className="col-4 p-0">{props.data.totalTeams}</h6>
          </div>
        </div>
        <div className="member col-12 pt-1 p-0">
          <div className="text row m-0">
            <h4 className="col-8 p-0">Franchise Member</h4>
            <h6 className="col-4 p-0">{props.data.franchiseName}</h6>
          </div>
        </div>

        <div className="owner col-12 pt-3 p-0">
          <div className="text row m-0">
            <h4 className="col-8 p-0">Franchise Owner</h4>
            <h6 className="col-4 p-0">{props.data.franchiseOwnerName}</h6>
          </div>
        </div>

      </div>
    </div>
  );
}

export default UserDetailsInfo;