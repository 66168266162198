import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Edit as EditIcon } from "@mui/icons-material";
import { NotificationManager } from "react-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";

import DeleteModal from "../../components/DeleteModal/deletemodal";
import AddEditModal from "./components/AddEditModal/addEditModal";

import EditModal from "./components/AddEditModal/editModal";
import {
  getTournaments,
  createTournament,
  deleteTournament,
  updateTournament,
  exportData,
} from "./reducers";
import {
  allWordsCapitalize,
  dateTimeFormate,
  tooltipTrim,
} from "../../../utils/apiutils";
import { getGames } from "../Games/reducers";
import { headCells } from "./components/tableColumns";
import useTable from "../../components/UseTable/useTable";
import GeneralText from "../../../User/components/generalText/generalText";
import ExportData from "../../components/ExportData/exportData";
import FilterBar from "../../../User/components/platformTypeBar/platformTypeBar";
import { capitalize } from "../../../utils/apisauce";
import AddTeamModal from "./components/AddEditModal/addTeamModal";
import { getUsers } from "../Users/reducers";

const Types = ["All", "General", "Grand Prix"];
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function EnhancedTable() {
  const navigate = useNavigate();
  const token = localStorage.getItem("adminToken");
  const [tournamentId, setTournamentId] = useState(0);
  const [filter, setFilter] = useState("All");
  const [searchedVal, setSearchedVal] = useState("");
  const [deleteModel, setDeleteModal] = React.useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [tournament, setTournament] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [teams, setTeams] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(undefined);
  const dispatch = useDispatch();
  const tournaments = useSelector((state) => state.tournaments);
  const users = useSelector((state) => state.users.users);
  const tournamentPage = useSelector(state => state.tournaments.tournaments.page)
  const games = useSelector((state) => state.games);
  const { data, total, page: pageNo } = tournaments.tournaments;
  const { exporturl } = tournaments;
  const { TblContainer, TblHead, TblToolbar, ComponentContainer, Pagination } =
    useTable(data, headCells);

  let pageCpy = pageNo;

  useEffect(() => {
    dispatch(getTournaments({ query: "" }));
    dispatch(getGames({ query: "" }));
    dispatch(getGames({ query: "" }));
    dispatch(getUsers({ query: "" }));
    dispatch(exportData());
  }, []);
  useEffect(() => {
    window.scroll(window.scrollX, window.scrollY);
  });

  const handleCreateTournament = (values) => {
    setAddModal((val) => !val);
    dispatch(createTournament(values));
  };

  const handleAddTeam = (values) => {
    setAddTeamModal((val) => !val);
    // dispatch(createTournament(values));
  };
  const handleEditTournament = (values) => {
    setEditModal((val) => !val);
    dispatch(updateTournament(values));
    setSelected([]);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    let newPageCpy = newPage;
    const typeParam =
      filter === "Grand Prix"
        ? "franchise"
        : filter === "General"
          ? "general"
          : "";
    let temObj = { query: "", pageNo: ++newPageCpy };
    if (typeParam) {
      temObj.type = typeParam;
    }
    dispatch(
      getTournaments({ query: "", pageNo: newPageCpy, type: typeParam })
      // getTournaments(temObj)
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const confirmDeleteHandler = () => {
    if (itemToDelete) {
      dispatch(deleteTournament({ tournamentId: [itemToDelete] }));
      setItemToDelete(undefined);
    } else {
      let delObj = [];
      data?.map((e, i) => {
        selected.includes(e._id) ? delObj.push(e._id.toString()) : "";
      });
      {
        delObj.length > 0
          ? dispatch(deleteTournament({ tournamentId: delObj }))
          : NotificationManager.error("No item selected.");
      }
      // dispatch(deleteTournament({ tournamentId: delObj }));
      setSelected([]);
    }
    setDeleteModal(false);
  };

  const tournamentResult = (id) => {
    navigate(`/admin/tournament-results?id=${id}`);
  };
  const addTeamHandler = (row) => {
    setTournamentId(row._id);
    setTournament(row)
    setAddTeamModal(true);
  };

  const editClickHandler = (row) => {

    setFormData({
      _id: row._id,
      tournamentTitleImage: BASE_URL + "/" + row.tournamentTitleImage,
      tournamentName: row.tournamentName,
      gameToPlay: row.gameToPlay?._id,
      entryFee: row.entryFee,
      prize: row.prize,
      teamSize: row.teamSize,
      tournamentType: capitalize(row.tournamentType),
      totalTeams: row.totalTeams,
      startingDateAndTime: row.startingDateAndTime,
      description: row.description,
    });
    setEditModal(true);
  };

  const searchHandler = (val) => {
    setSearchedVal(val);
    dispatch(getTournaments({ query: val }));
  };
  const deleteModalHandler = (event, id) => {
    //onClick={(e) => deleteModalHandler(e, row._id)
    setDeleteModal(true);
    handleClick(event, id);
  };


  const handleTeamByAdmin = async (item) => {

    let teamId = item._id;
    let userId = item.teamLeader;
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/user/tournament-by-admin`,
          {
            teamId,
            userId,
            tournamentId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          NotificationManager.success(response?.data?.message);
          fetchData()
          dispatch(getTournaments({ query: "", pageNo: tournamentPage, }))
        })
        .catch((error) => {
          NotificationManager.error(error?.response?.data?.message);

          console.log({ error });
        });


    } catch (error) {
      console.log({ error });
    }
  };

  const fetchData = async () => {
    const token = localStorage.getItem("adminToken");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/team/team-all`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      let temp = response.data.teams;
      setTeams(temp);
      // NotificationManager.success(response?.result?.message);
    } catch (error) {
      console.log("error = ", error);
      // NotificationManager.error(error.response.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const singleTournamentDeleteHandler = (event, id) => {
    setDeleteModal(true);
    setItemToDelete(id);
  };


  const _data = data;
  const tournamentTypeFilterHandler = (val) => {
    setFilter(val);
    const typeParam =
      val === "Grand Prix" ? "franchise" : val === "General" ? "general" : "";

    // dispatch(getTournaments({ query: "", type: typeParam }));
    let temObj = { query: "" };
    if (typeParam) {
      temObj.type = typeParam;
    }
    dispatch(
      // getTournaments({ query: "", pageNo: ++newPageCpy, type: typeParam })
      getTournaments(temObj)
    );
  };

  return (
    <ComponentContainer>
      <DeleteModal
        open={deleteModel}
        handleClose={setDeleteModal}
        confirmDeleteHandler={confirmDeleteHandler}
      />
      <AddEditModal
        open={addModal}
        games={games?.games?.data}
        formData={formData}
        handleClose={setAddModal}
        handleCreateTournament={handleCreateTournament}



      />
      <AddTeamModal
        open={addTeamModal}
        handleClose={setAddTeamModal}
        handleAddTeam={handleAddTeam}
        users={users}
        tournamentId={tournamentId}
        tournament={tournament}
        handleTeamByAdmin={handleTeamByAdmin}
        teams={teams}
      />
      <EditModal
        open={editModal}
        games={games?.games?.data}
        formDataObj={formData}
        handleClose={setEditModal}
        handleEditTournament={handleEditTournament}
      />
      <Grid className="far-apart-center">
        <Typography sx={{ fontWeight: "bold", color: "white", mt: 2, mb: 2 }}>
          Tournaments
          <FilterBar
            types={Types}
            val={filter}
            valHanlder={(val) => tournamentTypeFilterHandler(val)}
          />
        </Typography>
        <Box>
          <ExportData exporturl={exporturl} />
        </Box>
      </Grid>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TblToolbar
          numSelected={selected.length}
          setAddModal={setAddModal}
          setDeleteModal={setDeleteModal}
          searchHandler={searchHandler}
          value={searchedVal}
          placeholder="'Tournament' search"
          deleteIcon={true}
        />
        {_data?.length ? (
          <TblContainer>
            <TblHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={_data?.length}
            />
            <TableBody>
              {_data?.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    style={{
                      backgroundColor: index % 2 == 0 ? "#4A4A4A" : "#282828",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row._id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {tooltipTrim(row?.tournamentName, 20)}
                    </TableCell>
                    <TableCell align="center">
                      {row?.tournamentType == "general"
                        ? "General"
                        : "Grand Prix"}
                    </TableCell>
                    <TableCell align="center">
                      {allWordsCapitalize(row?.gameToPlay?.gameName)}
                    </TableCell>
                    <TableCell align="center">
                      <PhotoProvider>
                        <PhotoView
                          src={BASE_URL + "/" + row.tournamentTitleImage}
                        >
                          <img
                            src={BASE_URL + "/" + row.tournamentTitleImage}
                            style={{ objectFit: "cover" }}
                            height="35px"
                            width="35px"
                          />
                        </PhotoView>
                      </PhotoProvider>
                    </TableCell>
                    <TableCell align="center">{row.entryFee}</TableCell>
                    <TableCell align="center">{row.prize}</TableCell>
                    <TableCell align="center">{row.teamSize}</TableCell>
                    <TableCell align="center">{row.totalTeams}</TableCell>
                    <TableCell align="center">
                      {dateTimeFormate(row.startingDateAndTime)}
                    </TableCell>
                    <TableCell align="center">
                      <>
                        <IconButton onClick={() => addTeamHandler(row)}>
                          <GroupAddIcon style={{ color: "#90caf8" }} />
                        </IconButton>
                        <IconButton onClick={() => tournamentResult(row._id)}>
                          <UpgradeIcon style={{ color: "#90caf8" }} />
                        </IconButton>
                        <IconButton onClick={() => editClickHandler(row)}>
                          <EditIcon style={{ color: "#90caf9" }} />
                        </IconButton>
                        <IconButton
                          onClick={(e) =>
                            singleTournamentDeleteHandler(e, row._id)
                          }
                        >
                          <DeleteIcon style={{ color: "#DF4646" }} />
                        </IconButton>
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        ) : (
          <Grid
            container
            sx={{ background: "#282828", width: "100%", height: "100px" }}
          >
            <GeneralText text="No Tournaments Found!" />
          </Grid>
        )}
        {/* <TablePagination
          style={{ display: "none" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={_data.length}
          rowsPerPage={rowsPerPage}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <Pagination
          page={--pageCpy}
          data={total}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </ComponentContainer>
  );
}
