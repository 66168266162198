export const headCells = [
  {
    id: "fullName",
    numeric: false,
    label: "Name",
  },
  {
    id: "transactionType",
    numeric: true,
    label: "Type",
  },
  {
    id: "email",
    numeric: true,
    label: "Email",
  },
  {
    id: "amount",
    numeric: true,
    label: "Amount",
  },
  {
    id: "status",
    numeric: true,
    label: "Status",
  },
  {
    id: "address",
    numeric: true,
    label: "Address",
  },
  {
    id: "actions",
    numeric: true,
    label: "Actions",
  },
];
