import React, { useState, useEffect } from "react";
import GeneralText from "../../../../../components/generalText/generalText";
import {
  SingleEliminationBracket,
  DoubleEliminationBracket,
  Match,
  MATCH_STATES,
  SVGViewer,
} from "@g-loot/react-tournament-brackets";
import { MatchData } from "./match";
import classes from "./schedule.module.scss";
import { Box } from "@mui/material";
import { NotificationManager } from "react-notifications";
export default function Schedule({ id }) {
  let [scheduleData, setScheduleData] = useState();
  useEffect(async () => {
    // setTimeout(() => {
    //   setShowmore(true)
    const token = localStorage.getItem("userToken");
    const getLoad = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    let res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/user/flschedule?fantasyLeagueId=${id}`,
      getLoad
    );
    await res
      .json()
      .then((data) => {
        if (data.code == 200) {
          setScheduleData(data.leagueScheduleData);
        } else if (data.code == 400) {
          NotificationManager.error(data.message);
        }
      })
      .catch((err) => {});

    // setScheduleData(data)
  }, []);

  const finalWidth = Math.max(2020, 500);
  const finalHeight = Math.max(1020, 2000);

  return (
    <>
      {scheduleData ? (
        <>
          {/* {singleRound?.matches?.length ? (
        // <RoundTable
        //   matches={singleRound?.matches}
        //   roundNo={roundNo}
        //   leagueScheduleData={data}
        // />
        <GeneralText text="No Schedule Found. Schedule will be auto generated on completion of all teams" />

      ) : (
        <GeneralText text="No Schedule Found. Schedule will be auto generated on completion of all teams" />
      )} */}
          <Box mt={2} className={classes.roundTable}>
            {/* <p>{matches.data.length}</p> */}
            {/* {matches?.data?.length ? (
          <SingleEliminationBracket
            matches={MatchData.leagueScheduleData}
            matchComponent={Match}
            svgWrapper={({ children, ...props }) => (
              <SVGViewer width={finalWidth}
                height={finalHeight} {...props} >
                {children}
              </SVGViewer>
            )}
          />
        ) : (
          <Grid
            container
            marginY={4}
            justifyContent="end"
            alignItems="center"
            sx={{ fontSize: "20px", color: "#484848" }}
          >
            <span>No Rounds Found! </span>
          </Grid>
        )
        } */}

            <SingleEliminationBracket
              matches={scheduleData}
              matchComponent={Match}
              svgWrapper={({ children, ...props }) => (
                <SVGViewer width={finalWidth} height={finalHeight} {...props}>
                  {children}
                </SVGViewer>
              )}
            />
          </Box>
        </>
      ) : (
        <GeneralText text="No Schedule Found. Schedule will be auto generated on completion of all teams" />
      )}
    </>
  );
}
