import React, { useState } from "react";
import { Box, Typography, Button, Grid, imageListItemBarClasses } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import "./inventoryCard.scss";
import { Row } from "reactstrap";
import axios from "axios";
import { NotificationManager } from "react-notifications";

export default function InventoryCard({
  img,
  title,
  date,
  id,
  item,
  forSale,
  forRedeem,
}) {
  const theme = createTheme({
    palette: {
      action: {
        disabledBackground: "#9a9797",
        disabled: "#fff",
      },
    },
  });

  const token = localStorage.getItem("userToken");
  const [selectedItem, setSelectedItem] = useState({});
  const [price, setPrice] = useState("");
  const [isForSaleDialogOpen, setIsForSaleDialogOpen] = useState(false);
  const [isSellDialogOpen, setIsSellDialogOpen] = useState(false);
  const [isConfirmSellDialogOpen, setIsConfirmSellDialogOpen] = useState(false);

  const handleSellDialogClose = () => {
    setIsSellDialogOpen(false);
  };

  const handleButtonClick = () => {
    sellInventoryItem();
    setIsForSaleDialogOpen(false);
  };

  const sellData = {
    userInventoryId: selectedItem._id,
    price: price,
  };

  const sellInventoryItem = () => {
    //Inventory sell api integration using axois
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/inventory/sell`, sellData, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        NotificationManager.success("Request Successful");
      })
      .catch((error) => { });
  };
  const handleSellanItemButtonClick = () => {
    setSelectedItem(item);
    setIsSellDialogOpen(true);
  };

  const handleConfirmSellDialogClose = () => {
    setIsSellDialogOpen(false);
  };

  const handleSellDialogClick = () => {
    setIsConfirmSellDialogOpen(true);
    setIsSellDialogOpen(false);
  };

  const handlePriceChange = (event) => {
    const inputPrice = event.target.value;

    // Validate if the input is a complete valid number
    const isValidNumber = /^\d*$/.test(inputPrice);

    // If the input is a valid number, update the price state
    if (isValidNumber) {
      const priceValue = inputPrice === "" ? "" : parseInt(inputPrice, 10); // Convert the input string to a number
      setPrice(priceValue);
    } else {
      // Display an error message or perform any other action
      NotificationManager.error("Please Enter a Valid Number!");
    }
  };
  const handleImageDialogClose = () => {
    setIsImageDialogOpen(false);
  };
  const handleisForSellDialogClick = () => {
    setIsForSaleDialogOpen(true);
    setIsConfirmSellDialogOpen(false);
  };

  const handleIsForSellDialogClose = () => {
    setIsForSaleDialogOpen(false);
  };

  const handleRedeem = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/inventory/redeem`,
        { userInventoryId: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      NotificationManager.success("Redeem Succesfully");
    } catch (error) { }
  };
  return (
    <>
      <Box width="100%" height="auto">
        <div className="card matchcard-gamecard">
          {img.endsWith(".mp4") || img.endsWith('.avi') || img.endsWith('.mov') || img.endsWith('.wmv') ? <video src={img} height="260" controls /> : <img
            alt=" "
            className="matchcard-gamecard-profilePic matchcard-inventorycard-profilePic"
            src={img}
          />}
          <div className="matchcard-card-body">
            <p className="matchcard-card-bodyhead">{title}</p>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ color: "gray" }}
            >
              {date}
            </Typography>
          </div>
          <Row>
            <ThemeProvider theme={theme}>
              <Button
                style={{ marginTop: "10px", marginBottom: "10px" }}
                variant="contained"
                sx={{
                  bgcolor: "#F26826",
                  color: "white",
                  "&:hover": { bgcolor: "#F26826" },
                }}
                disabled={!forSale}
                onClick={handleSellanItemButtonClick}
              >
                Sell
              </Button>

              <Button
                variant="contained"
                style={{}}
                sx={{
                  bgcolor: "#F26826",
                  color: "white",
                  "&:hover": { bgcolor: "#F26826" },
                }}
                disabled={!forRedeem}
                onClick={handleRedeem}
              >
                Redeem
              </Button>
            </ThemeProvider>
          </Row>
        </div>
      </Box>

      {/* sellDialog */}
      <Dialog
        open={isSellDialogOpen}
        onClose={handleSellDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            width: "632px",
            height: "266px",
            background: "#1A1A1A",
            border: "1px solid #FFFFFF4D",
            borderRadius: "8px",
            opacity: 1,
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "white",
          }}
        >
          Put an item up for sale
        </DialogTitle>
        <DialogContent>
          {selectedItem && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                {selectedItem?.inventoryId?.link.endsWith(".mp4") || selectedItem?.inventoryId?.link.endsWith('.avi') || selectedItem?.inventoryId?.link.endsWith('.mov') || selectedItem?.inventoryId?.link.endsWith('.wmv') ?
                  <video
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    controls
                    height="80" width="140"
                  /> : <img
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    style={{ width: "71px", height: "59px" }}
                  />}

                <span style={{ margin: 0, color: "white" }}>
                  {selectedItem?.inventoryId?.name}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <span style={{ color: "white" }}>Price: $ </span>
                <TextField
                  variant="outlined"
                  size="small"
                  style={{
                    width: "83px",
                    height: "42px",
                    border: "1px solid #696969",
                    borderRadius: "4px",
                    opacity: "1",
                    color: "white",
                    textAlign: "center",
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  value={price}
                  onChange={handlePriceChange}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#F26826",
              color: "white",
              "&:hover": { bgcolor: "#F26826" },
            }}
            onClick={handleSellDialogClick}
          >
            Put it up for sale
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirmsellDialog */}
      <Dialog
        open={isConfirmSellDialogOpen}
        onClose={handleConfirmSellDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            width: "632px",
            height: "266px",
            background: "#1A1A1A",
            border: "1px solid #FFFFFF4D",
            borderRadius: "8px",
            opacity: 1,
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "white",
          }}
        >
          Put an item up for sale
        </DialogTitle>
        <DialogContent>
          {selectedItem && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                {selectedItem?.inventoryId?.link.endsWith(".mp4") || selectedItem?.inventoryId?.link.endsWith('.avi') || selectedItem?.inventoryId?.link.endsWith('.mov') || selectedItem?.inventoryId?.link.endsWith('.wmv') ?
                  <video
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    controls
                    height="80" width="140"
                  /> : <img
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedItem?.inventoryId?.link}`}
                    alt={selectedItem?.inventoryId?.name}
                    style={{ width: "71px", height: "59px" }}
                  />}
                <span style={{ margin: 0, color: "white" }}>
                  {selectedItem?.inventoryId?.name}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "white" }}>Price: $ </span>

                <TextField
                  variant="outlined"
                  size="small"
                  style={{
                    width: "83px",
                    height: "42px",
                    border: "1px solid #696969",
                    borderRadius: "4px",
                    opacity: "1",
                    color: "white",
                    textAlign: "center",
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  value={price}
                  readOnly={true}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <div
            style={{
              width: "90%",
              margin: " auto",
            }}
          >
            {selectedItem && (
              <DialogContent
                sx={{
                  backgroundColor: "#282828",
                  color: "white",
                  padding: "10px",

                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "16px",
                      display: "inline",
                      color: "#A1A1A1",
                    }}
                  >
                    This item will not available in your inventory for use or
                    trade while it is for sale in Community Market.
                  </p>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#F26826",
                      color: "white",
                      "&:hover": { bgcolor: "#F26826" },
                    }}
                    onClick={handleisForSellDialogClick}
                  >
                    ok
                  </Button>
                </div>
              </DialogContent>
            )}
          </div>
        </DialogActions>
      </Dialog>
      {/* isForSaleDialog */}
      <Dialog
        open={isForSaleDialogOpen}
        onClose={handleIsForSellDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "632px",
            height: "186px",
            background: "#1A1A1A",
            border: "1px solid #FFFFFF4D",
            borderRadius: "8px",
          },
        }}
      >
        <div
          style={{
            width: "90%",
            margin: " auto",
          }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#282828",
              color: "white",
              padding: "10px",

              marginBottom: "10px",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex ",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "16px",
                  display: "inline",
                  color: "#A1A1A1",
                }}
              >
                Your item will list on Community Market. When it sells, you will
                get an email, and money from the sale will appear in your
                wallet.
              </p>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#F26826",
                  color: "white",
                  marginLeft: "auto", // Aligns the button to the right side
                }}
                onClick={handleButtonClick}
              >
                Ok
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}
