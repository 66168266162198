import { Modal, Box, Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import classes from "./addUserInventoryModal.module.scss";
import { Close, ClosedCaptionDisabledTwoTone } from "@mui/icons-material";

const MyModal = styled(Modal)({
  ".MuiBackdrop-root": {
    background: "transparent",
  },
  "& .MuiBox-root": {
    background: "#1a1a1a",
  },
});
const OkButton = styled(Button)(({ color }) => {
  return {
    backgroundColor: color ? "#4A4A4A" : "#F26826",
    padding: "7px 40px",
    color: "white",
    margin: "0px auto",
    "& :hover": {
      // backgroundColor: color ? "#4A4A4A" : "#F26826",
    },
  };
});

const GeneralModal = ({ open, handleClose, data, handleAddUserInventory }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <MyModal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className={classes.wrapper}>
        <div className={classes.closeBtn}>
          <span onClick={() => handleClose(false)}>
            <Close />
          </span>
        </div>
        {data?.length > 0 && (
          <div className={classes.scrollWrapper}>
            <Grid
              container
              pt={1}
              pb={3}
              className={classes.inventoryContainer}
            >
              {data?.map((item) => {
                return (
                  <Grid item className={classes.inventoryItem}>
                    <div className={classes.imageWrapper}>
                      {item.link.endsWith('.mp4') || item.link.endsWith('.avi') || item.link.endsWith('.mov') || item.link.endsWith('.wmv') ?

                        <video src={BASE_URL + "/" + item?.link} width="140" height="100%" controls />
                        :
                        <img
                          src={BASE_URL + "/" + item?.link}
                          className={classes.img}
                        />
                      }
                    </div>
                    <div className={classes.title}>
                      <h4>{item?.name}</h4>
                    </div>
                    <div>
                      <OkButton
                        style={{ marginRight: "15px" }}
                        type="button"
                        onClick={() => handleAddUserInventory(item?._id)}
                      >
                        {"Add"}
                      </OkButton>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
        {data?.length <= 0 && (
          <Grid container pt={1} pb={3} className={classes.notFoundContainer}>
            <h4>No Data Found. Kindly create inventory list.</h4>
          </Grid>
        )}
      </Box>
    </MyModal>
  );
};
export default GeneralModal;
