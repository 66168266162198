import React, { useState } from 'react';
import { Button, Modal, FormControlLabel, Checkbox } from '@mui/material';
import "./styles.css"

const AgeModal = () => {
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCheckChange = (event) => setChecked(event.target.checked);

  const handleSubmit = () => {
    // Add your submit logic here
    console.log('Form submitted');
    handleClose();
  };

  return (
    <div   >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className='mainDiv d-flex d-flex justify-content-center'>
          <div>
            <h3 className='d-flex justify-content-center text-align-center' style={{ fontWeight: "700" }}>Confirm Your Age</h3>
            <p style={{ textAlign: "center" }}>We need user to be 18 and above to continue.</p>
          </div>
          <div style={{ textAlign: "center", marginBottom: "15px", color: "#fff" }}>I confirm that I am 18 or above.           </div>
          <Button className='btnSubmit' onClick={handleSubmit}>Yes I'am</Button>
        </div>
      </Modal>
    </div>
  );
};

export default AgeModal;
