import React from "react";
import { Box, Typography } from "@mui/material";

const NoDataDiv = ({ text, height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height ? height : "100%",
        margin: "auto",
        width: "100%",
      }}
    >
      <Typography component="span" sx={{ color: "#484848", fontSize: 24, padding: "30px" }}>
        {text}
      </Typography>
    </Box>
  );
};

export default NoDataDiv;
