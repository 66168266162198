const TransactionDetailsRow = (props) => {
  return (
    <tr>
      <th scope="row">
        {new Date(props?.data?.createdAt).toLocaleDateString()}
      </th>
      <td>{props?.data?.type}</td>
      <td>{props?.data?.amount}</td>
    </tr>
  );
};

export default TransactionDetailsRow;
