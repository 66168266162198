import { createSlice } from "@reduxjs/toolkit";
import { checkAdminToken, checkUserToken } from "../../../utils/apisauce";
import { NotificationManager } from "react-notifications";
import PaymentModal from "./components/PaymentModal";

let payModal = false;
const slice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    username: "",
    password: "",
    error: "",
    profile: null,
    signUp: false,
    reset: false,
    // authenticated: checkToken(),
  },
  reducers: {
    login: (state, { payload }) => {
      state.loading = true;
      state.username = payload.email;
      state.password = payload.password;
    },
    loginSuccess: (state, { payload }) => {
      console.log("login success payload = ", payload.result);
      state.loading = false;
      if (payload.result.role === "Admin") {
        localStorage.setItem("adminToken", payload.token);
        state.profile = payload.result;
        localStorage.removeItem("userToken");
        localStorage.removeItem("user_id");
        checkAdminToken();
        setTimeout(() => {
          window.location.replace("/admin/dashboard");
        }, 700);
      } else if (payload.result.role === "User") {
        // if (!payload?.result?.paymentStatus) {
        //   localStorage.setItem("user_id", payload.result._id);
        //   window.location.replace("/auth/paypal");
        // } else {
        localStorage.setItem("userToken", payload.token);
        localStorage.setItem("stripeConnectId", payload.result.stripeConnectId);
        localStorage.setItem("user_id", payload.result._id);
        state.profile = payload.result;
        localStorage.removeItem("adminToken");
        checkUserToken();
        setTimeout(() => {
          window.location.replace("/user/profile");
        }, 700);
      }
      // payload?.result?.paymentStatus
      //   ?
      NotificationManager.success("Successfully logged in")
      // : NotificationManager.error("Pay First then Sign in");
      // }
    },
    loginFailed: (state, { payload }) => {
      state.loading = false;
      NotificationManager.error(payload.message);
    },
    signup: (state, { payload }) => {
      state.loading = true;
      state.username = payload.email;
      state.password = payload.password;
    },
    signupSuccess: (state, { payload }) => {
      console.log("inside signupSuccess")

      // if (payload?.result?.payment?.url !== undefined) {
      //   window.location.href = payload?.result?.payment?.url;
      // }
      state.loading = false;
      state.signUp = true;
      if (payload.result.role === "User") {
        state.profile = payload.result;
      }

      // if (Object.keys(payload.result.payment).length === 0) {

      //   NotificationManager.success(payload.message);

      // } else {

      NotificationManager.success("Successfully Signed up");
      console.log({ "sign up": state.signUp })

      // }
    },
    signupFailed: (state, { payload }) => {
      state.loading = false;
      state.signUp = false;

      console.log('Payload type:', typeof payload);
      if (!(JSON.stringify(payload) === '"SERVER_ERROR"'))
        NotificationManager.error(payload.message);
    },

    forgotPass: (state, { payload }) => { },
    forgotPassSuccess: (state, { payload }) => {
      NotificationManager.success("Email sent successfully");
    },
    forgotPassFailed: (state, { payload }) => {
      NotificationManager.error(payload.message);
    },
    resetPass: (state, payload) => { },
    resetPassSuccess: (state, { payload }) => {
      NotificationManager.success("Password updated successfully");
    },
    resetPassFailed: (state, { payload }) => {
      NotificationManager.error(payload.message);
    },
    changePass: (state, payload) => { },
    changePassSuccess: (state, { payload }) => {
      NotificationManager.success("Password changed successfully");
    },
    changePassFailed: (state, { payload }) => {
      NotificationManager.error(payload.message);
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFailed,
  signup,
  signupSuccess,
  signupFailed,
  resetPass,
  resetPassSuccess,
  resetPassFailed,
  forgotPass,
  forgotPassSuccess,
  forgotPassFailed,
  changePass,
  changePassSuccess,
  changePassFailed,
} = slice.actions;

export default slice.reducer;
