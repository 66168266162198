import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { NotificationManager } from "react-notifications";
import { Tooltip } from "@material-ui/core";

export default function GamesList() {
  const [gamesData, setGamesData] = useState([]);
  const userProfile = useSelector((state) => state.userProfile.profile);
  console.log({ userProfile })
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL + "/";
  useEffect(() => {
    fetchData();

  }, []);


  const fetchData = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user/games`,
        config
      );
      setLoading(false);

      setGamesData(response?.data?.gameData);
    } catch (error) {
      setLoading(false);
      // Notification.error(error.response.message);
    }
  };
  const handleFav = (id) => {
    axios
      .post(`${BASE_URL}api/user/markGameFav`, {
        id: localStorage.getItem("user_id"),
        gameId: id
      }, {
        headers: {
          "Authorization": `${localStorage.getItem("userToken")}`
        }
      }).then((res) => {
        NotificationManager.success(res.data.message)
        fetchData();
      }).catch((err) => {
        NotificationManager.error("Opps~! Something went wrong")
        console.log({ err })
      })

  }


  return (
    <>
      {loading ? (
        <Loader color={1} />
      ) : (
        <>
          {" "}
          <Box sx={{ marginBottom: "16px" }}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                component="span"
                sx={{ color: "white", fontSize: 24, fontWeight: "bold" }}
              >
                All Games
              </Typography>
            </Grid>
          </Box>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "30px",
              marginTop: "5px",
              marginLeft: "5px"
            }}
          >
            {gamesData.map((item) => (
              <Card style={{ border: "1px solid #fd7e14", backgroundColor: "#232323", width: "20%" }} sx={{ maxWidth: 345 }}>

                <CardMedia
                  component="img"
                  sx={{ height: 140 }}
                  src={BASE_URL + item?.gameImage}
                  alt="Game Image"
                />
                <CardContent>
                  <Tooltip title={item?.gameName}>
                    <Typography style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis', color: "white"
                    }} gutterBottom variant="h5" component="div">
                      {item?.gameName}
                    </Typography>
                  </Tooltip>

                </CardContent>
                <CardActions style={{ display: "flex", justifyContent: "flex-end", }}>
                  {item?.isFavorite === true ? <IconButton aria-label="add to favorites">
                    <FavoriteIcon style={{ color: "f26826", }} onClick={() => handleFav(item?._id)} />
                  </IconButton> : <IconButton aria-label="add to favorites">
                    <FavoriteBorderOutlinedIcon style={{ color: "f26826", }} onClick={() => handleFav(item?._id)} />
                  </IconButton>}
                </CardActions>
              </Card>
            ))}
          </Grid>
        </>
      )
      }
    </>
  );
}
