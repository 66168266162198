import * as React from "react";
import { useState, useEffect } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

import {
  Edit as EditIcon,
  Search as SearchIcon,
  Add,
} from "@mui/icons-material";
import {
  Grid,
  Box,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";
import classes from "./index.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";

import DeleteModal from "../../components/DeleteModal/deletemodal";
import AddEditModal from "./components/AddEditModal/addEditModal";
import EditModal from "./components/AddEditModal/editModal";
import {
  getInventory,
  createInventory,
  updateInventory,
  deleteInventory,
  exportData,
} from "./reducers";
import { dateTimeFormate, tooltipTrim } from "../../../utils/apiutils";
import { headCells } from "./components/tableColumns";
import useTable from "../../components/UseTable/useTable";
import GeneralText from "../../../User/components/generalText/generalText";
import ExportData from "../../components/ExportData/exportData";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Inventory() {
  const [searchedVal, setSearchedVal] = useState("");
  const [deleteModel, setDeleteModal] = React.useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [itemToDelete, setItemToDelete] = useState(undefined);

  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventory);
  const { data, total, page } = inventory.inventory;
  const { exporturl } = inventory;
  const { TblToolbar, ComponentContainer, Pagination } = useTable(
    data,
    headCells
  );

  let pageCpy = page;

  useEffect(() => {
    dispatch(getInventory({ query: "" }));
    dispatch(exportData());
  }, []);
  useEffect(() => {
    window.scroll(window.scrollX, window.scrollY);
  });

  const handleCreateInventory = (values) => {
    dispatch(createInventory(values));
    setAddModal((val) => !val);
  };
  const handleEditInventory = (values) => {
    setEditModal((val) => !val);
    dispatch(updateInventory(values));
    setSelected([]);
  };

  const confirmDeleteHandler = () => {
    if (itemToDelete) {
      dispatch(deleteInventory({ _id: itemToDelete }));
      setItemToDelete(undefined);
    }
    setDeleteModal(false);
  };
  const editClickHandler = (row) => {
    setFormData({
      _id: row._id,
      inventory: BASE_URL + "/" + row.link,
      name: row.name,
      forSale: row.forSale,
      forRedeem: row.forRedeem,
    });
    setEditModal(true);
  };
  const searchHandler = (val) => {
    setSearchedVal(val);
    dispatch(getInventory({ query: val }));
  };

  const singleInventoryDeleteHandler = (event, id) => {
    setDeleteModal(true);
    setItemToDelete(id);
  };

  return (
    <ComponentContainer>
      <Grid className="far-apart-center">
        <Typography sx={{ fontWeight: "bold", color: "white", mt: 2, mb: 2 }}>
          Inventory
        </Typography>
        <Box>
          <ExportData exporturl={exporturl} />
        </Box>
      </Grid>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TblToolbar
          numSelected={selected.length}
          setAddModal={setAddModal}
          setDeleteModal={setDeleteModal}
          searchHandler={searchHandler}
          value={searchedVal}
          placeholder="'Inventory' search"
          deleteIcon={false}
        />
        {!data?.length ? (
          <Grid
            container
            sx={{ background: "#282828", width: "100%", height: "100px" }}
          >
            <GeneralText text="No Inventory Found!" />
          </Grid>
        ) : (
          <Grid
            container
            className={classes.inventory}
            sx={{ background: "#282828", width: "100%", minHeight: "100px" }}
          >
            {data?.map((item) => {
              return (
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.intventoryItem}
                >
                  <Grid container xs={12} className={classes.wrapper}>
                    <Grid item xs={6} className={classes.imageWrapper}>
                      {item.link.endsWith('.mp4') || item.link.endsWith('.avi') || item.link.endsWith('.mov') || item.link.endsWith('.wmv') ?

                        <video src={BASE_URL + "/" + item?.link} width="240" height="150" controls />
                        :
                        <img
                          src={BASE_URL + "/" + item?.link}
                          className={classes.img}
                        />
                      }
                    </Grid>
                    <Grid item xs={6} className={classes.contentWrapper}>
                      <h4>{item?.name}</h4>
                      <div>
                        <IconButton onClick={() => editClickHandler(item)}>
                          <EditIcon
                            sx={{
                              color: "#000000 !important",
                              background: "#a19e9e",
                              padding: "2px",
                              borderRadius: "2px",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={(e) =>
                            singleInventoryDeleteHandler(e, item._id)
                          }
                        >
                          <DeleteIcon
                            sx={{
                              color: "#DF4646 !important",
                              background: "#a19e9e",
                              padding: "2px",
                              borderRadius: "2px",
                            }}
                          />
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* <TablePagination
          style={{ display: "none" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Pagination
          data={total}
          page={--pageCpy}
          handleChangePage={handleChangePage}
        /> */}
      </Paper>
      <DeleteModal
        open={deleteModel}
        handleClose={setDeleteModal}
        confirmDeleteHandler={confirmDeleteHandler}
      />
      <AddEditModal
        open={addModal}
        handleClose={setAddModal}
        handleCreateInventory={handleCreateInventory}
      />
      <EditModal
        open={editModal}
        inventory={inventory?.inventory?.data}
        formDataObj={formData}
        handleClose={setEditModal}
        handleEditInventory={handleEditInventory}
      />
    </ComponentContainer>
  );
}
