import React from "react";
import "./Loader.css"; // Import CSS for styling loader (you can customize this)

const Loader = ({ color }) => {
  return (
    <>
      {color === 1 ? (
        <>
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <>
          {color === 0 && (
            <div className="loader-container1">
              <div className="loader1"></div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Loader;
