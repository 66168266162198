import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const DEFAULT_TRANSACTIONS = {
  data: [],
  limit: 0,
  total: 0, 
  page: 0,
}

const slice = createSlice({
  name: "transactions",
  initialState: {
    loading: false,
    created: false,
    submitting: false,
    transactions: DEFAULT_TRANSACTIONS,
    error: "",
    deleted: null,
  },
  reducers: {
    getTransactions: (state, { payload }) => {
      state.loading = true;
    },
    getTransactionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions = {
        data: payload.result.data,
        page: parseInt(payload.result?.pagination.currentPage),
        total: parseInt(payload.result?.pagination.total)
      };
    },
    getTransactionsFailed: (state, { payload }) => {
      NotificationManager.error(payload.message);
    },
  },
});

export const {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
} = slice.actions;

export default slice.reducer;
