import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { Modal, Box, Button, Grid } from "@mui/material";
import classes from "./addEditModal.module.scss";
import { Close, ClosedCaptionDisabledTwoTone } from "@mui/icons-material";
import { useSelector } from "react-redux";

const MyModal = styled(Modal)({
  ".MuiBackdrop-root": {
    background: "transparent",
  },
  "& .MuiBox-root": {
    background: "#1a1a1a",
  },
});
const OkButton = styled(Button)(({ color }) => {
  return {
    backgroundColor: color ? "#4A4A4A" : "#F26826",
    padding: "7px 40px",
    color: "white",
    margin: "0px auto",
    "& :hover": {
      // backgroundColor: color ? "#4A4A4A" : "#F26826",
    },
  };
});
const AddTeamModal = ({ open, handleClose, tournamentId, teams, handleTeamByAdmin, tournament }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const allTournaments = useSelector(state => state.tournaments.tournaments.data)
  const [participatingTeams, setParticipatingTeams] = useState(tournament.participatingTeams);

  useEffect(() => {
    let matchedTournament = allTournaments.find(tournament => tournament._id === tournamentId);
    setParticipatingTeams(matchedTournament?.participatingTeams)
  }, [tournament])


  return (
    <MyModal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className={classes.wrapper}>
        <div className={classes.closeBtn}>
          <span onClick={() => handleClose(false)}>
            <Close />
          </span>
        </div>

        {teams?.length > 0 && (
          <div className={classes.scrollWrapper}>
            <Grid
              container
              pt={1}
              pb={3}
              className={classes.inventoryContainer}
            >
              {teams?.map((item) => {
                return (
                  <Grid key={item._id} item className={classes.inventoryItem}>
                    <div className={classes.imageWrapper}>
                      <img src={BASE_URL + "/" + item?.teamTitleImage} />
                    </div>
                    <div className={classes.title}>
                      <h4>{item?.teamViewName}</h4>
                    </div>
                    {
                      participatingTeams?.includes(item._id) ? <div>
                        <OkButton
                          type="button"
                          onClick={() => handleTeamByAdmin(item)}
                        >
                          {"Remove"}
                        </OkButton>
                      </div> :
                        <div>
                          <OkButton
                            type="button"
                            onClick={() => handleTeamByAdmin(item)}
                          >
                            {"Add"}
                          </OkButton>
                        </div>}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
        {teams?.length <= 0 && (
          <Grid container pt={1} pb={3} className={classes.notFoundContainer}>
            <h4>No Teams Found.</h4>
          </Grid>
        )}
      </Box>
    </MyModal>
  );
};
export default AddTeamModal;
