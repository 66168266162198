import { takeLatest, call, put } from "redux-saga/effects";

import {
  getUserDetailsInfo,
  getUserDetailsFailed,
  getUserDetailsInfoSuccess,
  getUserInventory,
  getUserInventorySuccess,
  getUserInventoryFailed,
  getUserOneToOneMatchesDetails,
  getUserOneToOneMatchesDetailsSuccess,
  getUserTransactionDetails,
  getUserTransactionDetailsSuccess,
  getUserFantasyLeagueDetails,
  getUserFantasyLeagueDetailsSuccess,
  getUserGpLeagueDetails,
  getUserGpLeagueDetailsSuccess,
  getUserLadderDetails,
  getUserLadderDetailsSuccess,
  getUserTournamentDetails,
  getUserTournamentDetailsSuccess,
} from "./reducers";

import request from "../../../utils/apisauce";

function* getUserDetailsInfoSaga({ payload }) {
  let action;
  let url = "/api/admin/userInfoForAdmin";
  try {
    if ("_id" in payload) {
      url += `?_id=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);
    action = getUserDetailsInfoSuccess(response.result);
  } catch (e) {
    action = getUserDetailsFailed(e);
  }
  yield put(action);
}

function* getUserOneToOneMatchesDetailsSaga({ payload }) {
  let action;
  let url = "/api/admin/matchInfoForAdmin";
  try {
    if ("_id" in payload) {
      url += `?_id=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);
    action = getUserOneToOneMatchesDetailsSuccess(response.matchInfoData);
  } catch (e) {
    action = getUserDetailsFailed(e);
  }
  yield put(action);
}

function* getUserFantasyLeagueDetailsSaga({ payload }) {
  let action;
  let url = "/api/admin/fantasyLeagueInfoForAdmin";
  try {
    if ("_id" in payload) {
      url += `?_id=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);
    action = getUserFantasyLeagueDetailsSuccess(response.flLeagueInfoData);
  } catch (e) {
    action = getUserDetailsFailed(e);
  }
  yield put(action);
}

function* getUserLadderDetailsSaga({ payload }) {
  let action;
  let url = "/api/admin/ladderInfoForAdmin";
  try {
    if ("_id" in payload) {
      url += `?_id=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);

    action = getUserLadderDetailsSuccess(response.ladderInfoData);
  } catch (e) {
    action = getUserDetailsFailed(e);
  }
  yield put(action);
}

function* getUserTournamentDetailsSaga({ payload }) {
  let action;
  let url = "/api/admin/tournamentInfoForAdmin";
  try {
    if ("_id" in payload) {
      url += `?_id=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);

    action = getUserTournamentDetailsSuccess(response.tournamentInfoData);
  } catch (e) {
    action = getUserDetailsFailed(e);
  }
  yield put(action);
}

function* getUserGpLeagueDetailsSaga({ payload }) {
  let action;
  let url = "/api/admin/gpLeagueInfoForAdmin";
  try {
    if ("_id" in payload) {
      url += `?_id=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);
    action = getUserGpLeagueDetailsSuccess(response.gpLeagueInfoData);
  } catch (e) {
    action = getUserDetailsFailed(e);
  }
  yield put(action);
}

function* getUserTransactionDetailsSaga({ payload }) {
  let action;
  let url = "/api/admin/transactionInfoForAdmin";
  try {
    if ("_id" in payload) {
      url += `?_id=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);
    action = getUserTransactionDetailsSuccess(response.transactionInfoData);
  } catch (e) {
    action = getUserDetailsFailed(e);
  }
  yield put(action);
}

function* getUserInventoryDetailsSaga({ payload }) {
  let action;
  let url = "api/inventory/list";
  try {
    if ("_id" in payload) {
      url += `?userId=${payload["_id"]}`;
    }

    const response = yield call(request, "GET", url);
    action = getUserInventorySuccess(response.result);
  } catch (e) {
    action = getUserInventoryFailed(e);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getUserDetailsInfo, getUserDetailsInfoSaga);
  yield takeLatest(
    getUserOneToOneMatchesDetails,
    getUserOneToOneMatchesDetailsSaga
  );
  yield takeLatest(getUserTransactionDetails, getUserTransactionDetailsSaga);
  yield takeLatest(getUserInventory, getUserInventoryDetailsSaga);
  yield takeLatest(
    getUserFantasyLeagueDetails,
    getUserFantasyLeagueDetailsSaga
  );
  yield takeLatest(getUserGpLeagueDetails, getUserGpLeagueDetailsSaga);
  yield takeLatest(getUserLadderDetails, getUserLadderDetailsSaga);
  yield takeLatest(getUserTournamentDetails, getUserTournamentDetailsSaga);
}
