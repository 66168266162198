import classes from "./index.module.scss";
import { Formik } from "formik";
import Input from "../../../Admin/components/Input/input";
import FormSubmitButton from "../../../Admin/components/FormSubmitButton/formSubmitbutton";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { sendSupportMessage } from "./reducers";

export default function Support() {
  const dispatch = useDispatch();

  const sendMessage = (values) => {
    dispatch(sendSupportMessage(values));
  };

  return (
    <>
      <div className={classes.formWrapper}>
        <div className={classes.formTitle}>
          <Typography component="h1" className={classes.heading}>
            Report Issue
          </Typography>
          <Typography component="p" className={classes.subHeading}>
            to: support@onlinebattleground.com
          </Typography>
        </div>
        <div className={classes.form}>
          <Formik
            initialValues={{
              subject: "",
              msgBody: "",
            }}
            onSubmit={(values) => sendMessage(values)}
          >
            {({ values, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Input
                    placeholder="Subject"
                    name="subject"
                    type="text"
                    required={true}
                    onchange={handleChange}
                    value={values.subject}
                  />
                  <Input
                    placeholder="Message"
                    name="msgBody"
                    type="text"
                    required={true}
                    onchange={handleChange}
                    value={values.msgBody}
                  />
                  <FormSubmitButton
                    title={"Send"}
                    clickHandler={function () {}}
                  />
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}
