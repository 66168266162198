import React, { useState } from "react";
import {
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/styles";
import { useDispatch } from "react-redux";

import VideoViewer from "../../../../common/videoViewer/videoViewer";
import PhotoViewer from "../../../../common/photoViewer/photoViewer";
import { nestedColumnCells } from "./tableColumns";
import StatusModal from "./StatusModal";
import {
  allWordsCapitalize,
  isImage,
  tooltipTrim,
} from "../../../../utils/apiutils";
import { submitResult } from "../reducers";
import classes from "../index.module.scss";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const MyNestedTable = styled(Table)({
  color: "white",
  "& .MuiTableCell-root": {
    color: "white",
    padding: "10px 0px",
  },
  "& .MuiCheckbox-root": {
    color: "white",
  },
});

const MyTblRow = ({ row, index, labelId, isItemSelected, handleClick }) => {
  const [open, setOpen] = useState();
  const [winlossStatus, setWinlossStatus] = useState({
    open: false,
    status: "",
    id: 0,
    userId: 0,
    matchId: null,
  });
  const dispatch = useDispatch();
  const winlossStatusHandler = () => {
    let { id, status, userId, matchId } = winlossStatus;
    dispatch(
      submitResult({
        resultId: id,
        userId: userId,
        resultStatus: status,
        matchId,
      })
    );
    setWinlossStatus({
      open: false,
      status: "",
      id: 0,
      userId: 0,
      matchId: null,
    });
  };
  const statusModalCloseHandler = () => {
    setWinlossStatus({
      open: false,
      status: "",
      id: 0,
      userId: 0,
      matchId: null,
    });
  };

  return (
    <>
      <TableRow
        hover
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row._id}
        selected={isItemSelected}
        style={{
          backgroundColor: index % 2 == 0 ? "#4A4A4A" : "#282828",
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, row._id)}
            role="checkbox"
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ color: "black", fontWeight: "bold" }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} Click
            Me
          </IconButton>
        </TableCell>

        <TableCell align="left">{tooltipTrim(row?.matchName, 20)}</TableCell>
        <TableCell align="center">{row.gameToPlay.gameName}</TableCell>
        <TableCell align="center">
          {allWordsCapitalize(
            row.winningUser?.userDetail?.userName ?? "Pending"
          )}
        </TableCell>
        {/* <TableCell align="center">
          <IconButton onClick={(e) => deleteHandler(e, row._id)}>
            <DeleteIcon style={{ color: "#DF4646" }} />
          </IconButton>
        </TableCell> */}
      </TableRow>
      <TableRow sx={{ width: "100%", backgroundColor: "rgb(26, 26, 26)" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <MyNestedTable aria-labelledby="tableTitle" size={"large"}>
              <TableHead style={{ background: "#1A1A1A" }}>
                <TableRow>
                  {nestedColumnCells.map((headCell, i) => (
                    <TableCell key={i} align={"center"}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {row?.resultChallengeBy != null ? (
                  <TableRow>
                    <TableCell align="center">
                      {allWordsCapitalize(
                        row?.resultChallengeBy?.results?.playerName
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeBy?.results?.score}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeBy?.results?.mapScoreOne ==
                        undefined ||
                      row?.resultChallengeBy?.results?.mapScoreOne == ""
                        ? "N/A"
                        : row?.resultChallengeBy?.results?.mapScoreOne}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeBy?.results?.mapScoreTwo ==
                        undefined ||
                      row?.resultChallengeBy?.results?.mapScoreTwo == ""
                        ? "N/A"
                        : row?.resultChallengeBy?.results?.mapScoreTwo}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeBy?.results?.mapScoreThree ==
                        undefined ||
                      row?.resultChallengeBy?.results?.mapScoreThree == ""
                        ? "N/A"
                        : row?.resultChallengeBy?.results?.mapScoreThree}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeBy?.results?.mapScoreFour ==
                        undefined ||
                      row?.resultChallengeBy?.results?.mapScoreFour == ""
                        ? "N/A"
                        : row?.resultChallengeBy?.results?.mapScoreFour}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeBy?.results?.mapScoreFive ==
                        undefined ||
                      row?.resultChallengeBy?.results?.mapScoreFive == ""
                        ? "N/A"
                        : row?.resultChallengeBy?.results?.mapScoreFive}
                    </TableCell>

                    <TableCell align="center">
                      {isImage(
                        BASE_URL + row?.resultChallengeBy?.results?.resultVideo
                      ) ? (
                        <PhotoViewer
                          url={row?.resultChallengeBy?.results?.resultVideo}
                        />
                      ) : (
                        <VideoViewer
                          videoUrl={
                            row?.resultChallengeBy?.results?.resultVideo
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeBy?.results?.result === "pending" ? (
                        <>
                          <Typography
                            className={classes.winlossButtons}
                            sx={{
                              background: "#156502",
                              pointerEvents:
                                row?.resultChallengeBy?.results?.result ===
                                "win"
                                  ? "none"
                                  : "all",
                            }}
                            variant="span"
                            onClick={() =>
                              setWinlossStatus({
                                open: true,
                                status: "win",
                                id: row._id,
                                userId:
                                  row?.resultChallengeBy?.results?.playerId,
                                matchId: row?.resultChallengeBy?.matchId,
                              })
                            }
                          >
                            Win
                          </Typography>

                          <Typography
                            className={classes.winlossButtons}
                            variant="span"
                            sx={{
                              background: "#610000",
                              pointerEvents:
                                row?.resultChallengeBy?.results?.result ===
                                "loss"
                                  ? "none"
                                  : "all",
                            }}
                            onClick={() =>
                              setWinlossStatus({
                                open: true,
                                status: "loss",
                                id: row._id,
                                userId:
                                  row?.resultChallengeBy?.results?.playerId,
                                matchId: row?.resultChallengeBy?.matchId,
                              })
                            }
                          >
                            Loss
                          </Typography>
                        </>
                      ) : (
                        <>
                          {row?.resultChallengeBy?.results?.result === "win" ? (
                            <Typography
                              className={classes.winlossButtons}
                              sx={{
                                background: "#156502",
                                pointerEvents:
                                  row?.resultChallengeBy?.results?.result ===
                                  "win"
                                    ? "none"
                                    : "all",
                              }}
                              variant="span"
                            >
                              Win
                            </Typography>
                          ) : (
                            <Typography
                              className={classes.winlossButtons}
                              variant="span"
                              sx={{
                                background: "#610000",
                                pointerEvents:
                                  row?.resultChallengeBy?.results?.result ===
                                  "loss"
                                    ? "none"
                                    : "all",
                              }}
                            >
                              Loss
                            </Typography>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ) : null}

                {row?.resultChallengeTo != null ? (
                  <TableRow>
                    <TableCell align="center">
                      {allWordsCapitalize(
                        row?.resultChallengeTo?.results?.playerName
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeTo?.results?.score}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeTo?.results?.mapScoreOne ==
                        undefined ||
                      row?.resultChallengeTo?.results?.mapScoreOne == ""
                        ? "N/A"
                        : row?.resultChallengeTo?.results?.mapScoreOne}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeTo?.results?.mapScoreTwo ==
                        undefined ||
                      row?.resultChallengeTo?.results?.mapScoreTwo == ""
                        ? "N/A"
                        : row?.resultChallengeTo?.results?.mapScoreTwo}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeTo?.results?.mapScoreThree ==
                        undefined ||
                      row?.resultChallengeTo?.results?.mapScoreThree == ""
                        ? "N/A"
                        : row?.resultChallengeTo?.results?.mapScoreThree}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeTo?.results?.mapScoreFour ==
                        undefined ||
                      row?.resultChallengeTo?.results?.mapScoreFour == ""
                        ? "N/A"
                        : row?.resultChallengeTo?.results?.mapScoreFour}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeTo?.results?.mapScoreFive ==
                        undefined ||
                      row?.resultChallengeTo?.results?.mapScoreFive == ""
                        ? "N/A"
                        : row?.resultChallengeTo?.results?.mapScoreFive}
                    </TableCell>

                    <TableCell align="center">
                      {isImage(
                        BASE_URL + row?.resultChallengeTo?.results?.resultVideo
                      ) ? (
                        <PhotoViewer
                          url={row?.resultChallengeTo?.results?.resultVideo}
                        />
                      ) : (
                        <VideoViewer
                          videoUrl={
                            row?.resultChallengeTo?.results?.resultVideo
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row?.resultChallengeTo?.results?.result === "pending" ? (
                        <>
                          <Typography
                            className={classes.winlossButtons}
                            sx={{
                              background: "#156502",
                              pointerEvents:
                                row?.resultChallengeTo?.results?.result ===
                                "win"
                                  ? "none"
                                  : "all",
                            }}
                            variant="span"
                            onClick={() =>
                              setWinlossStatus({
                                open: true,
                                status: "win",
                                id: row._id,
                                userId:
                                  row?.resultChallengeTo?.results?.playerId,
                                matchId: row?.resultChallengeTo?.matchId,
                              })
                            }
                          >
                            Win
                          </Typography>

                          <Typography
                            className={classes.winlossButtons}
                            variant="span"
                            sx={{
                              background: "#610000",
                              pointerEvents:
                                row?.resultChallengeTo?.results?.result ===
                                "loss"
                                  ? "none"
                                  : "all",
                            }}
                            onClick={() =>
                              setWinlossStatus({
                                open: true,
                                status: "loss",
                                id: row._id,
                                userId:
                                  row?.resultChallengeTo?.results?.playerId,
                                matchId: row?.resultChallengeTo?.matchId,
                              })
                            }
                          >
                            Loss
                          </Typography>
                        </>
                      ) : (
                        <>
                          {row?.resultChallengeTo?.results?.result ===
                          "loss" ? (
                            <Typography
                              className={classes.winlossButtons}
                              variant="span"
                              sx={{
                                background: "#610000",
                                pointerEvents:
                                  row?.resultChallengeTo?.results?.result ===
                                  "loss"
                                    ? "none"
                                    : "all",
                              }}
                            >
                              Loss
                            </Typography>
                          ) : (
                            <Typography
                              className={classes.winlossButtons}
                              sx={{
                                background: "#156502",
                                pointerEvents:
                                  row?.resultChallengeBy?.results?.result ===
                                  "win"
                                    ? "none"
                                    : "all",
                              }}
                              variant="span"
                            >
                              Win
                            </Typography>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </MyNestedTable>
          </Collapse>
        </TableCell>
      </TableRow>
      <StatusModal
        open={winlossStatus.open}
        handleClose={statusModalCloseHandler}
        confirmHandler={winlossStatusHandler}
      />
    </>
  );
};

export default MyTblRow;
