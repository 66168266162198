import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputBase, Box } from "@mui/material";
import { format } from "timeago.js";
import { useRef } from "react";
import io from "socket.io-client";

import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

import profileIconImg from "../../../../../assets/teamprofile.png";
import sendMessageIcon from "../../../../../assets/sendMessageIcon.svg";
import { allWordsCapitalize } from "../../../../../utils/apiutils";
import { Context } from "../../index";
import classes from "./PublicConversation.module.scss";
import {
  getPublicMsgs,
  setPublicMsgs,
  getPublicConversations,
} from "../../reducers";

const loggedinId = localStorage.getItem("user_id");
const BASE_URL = process.env.REACT_APP_BASE_URL + "/";

export default function ({publicMessages}) {
  const [msgVal, setMsgVal] = useState("");
  const [toggleOnlineUsers, setToggleOnlineUsers] = useState("open"); //open, close
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const scrollRef = useRef();
  const socket = useRef();
  const dispatch = useDispatch();
  //const { publicMessages } = useSelector((state) => state.userChat);
  const { profile } = useSelector((state) => state.userProfile);
  const { onlineUsers } = useContext(Context);

  useEffect(() => {
    dispatch(getPublicConversations({}));
  }, []);

  useEffect(() => {
    socket.current = io(BASE_URL);
    socket.current.on("getPublicMessage", (data) => {
      dispatch(getPublicConversations({}));
    });
  }, []);

  const colors = ["#77F226", "#F26826", "#F22626", "#F3982A"];

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }, []);

  const dispatchPublicMsgHandler = (msgObj) => {
    socket.current.emit("sendPublicMessage", {
      senderId: loggedinId,
      conversationMode: "Public",
      text: msgObj.text,
    });
  };

  const onlineUsersList = () => {
    return (
      <>
        <div className={classes.onlineUsers}>
          <div
            className={classes.onlineUsersToggle}
            onClick={() =>
              setToggleOnlineUsers(
                toggleOnlineUsers === "close" ? "open" : "close"
              )
            }
          >
            {toggleOnlineUsers === "close" ? (
              <VisibilityOutlined style={{ color: "#fff", width: "20px" }} />
            ) : (
              <VisibilityOffOutlined style={{ color: "#fff", width: "20px" }} />
            )}
          </div>
          <div
            className={`${classes.onlineUsersDrawer} ${classes[toggleOnlineUsers]}`}
          >
            {onlineUsers.map((user, index) => {
              return (
                <div className={classes.onlineUser} title={user.userName} key={index}>
                  <div className={classes.imgWrapper}>
                    <img
                      alt=""
                      src={
                        user.profileImage? BASE_URL + user.profileImage : profileIconImg
                      }
                    />
                  </div>
                  <span
                    className={true ? classes.online : classes.offline}
                  ></span>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const innerChat = () => {
    return (
      <div className={classes.chatContainer}>
        {publicMessages.map((msg, i) => {
          return (
            <div className={classes.msgRow} ref={scrollRef} key={i}>
              <img
                alt=""
                className={classes.msgPic}
                src={
                  msg.sender?.profileImage
                    ? BASE_URL + msg.sender?.profileImage
                    : profileIconImg
                }
              />
              <div className={classes.msgBox}>
                <span
                  className={classes.msgUserName}
                  style={{ color: "#F26826" }}
                >
                  {msg.sender?.userDetail?.userName}
                </span>
                &nbsp;
                <span className={classes.msgTime}>{format(msg.createdAt)}</span>
                <p className={classes.msgText}>{msg?.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const footer = () => {
    const sendMsgHandler = (e) => {
      if (e.key == "Enter" && msgVal !== "") {
        dispatchPublicMsgHandler({
          text: msgVal,
        });
        setMsgVal("");
      }
    };
    const sendClickhandler = () => {
      if (msgVal) {
        dispatchPublicMsgHandler({
          text: msgVal,
        });
        setMsgVal("");
      }
    };

    return (
      <Box className={classes.footer}>
        <InputBase
          placeholder={"Enter your message "}
          classes={{
            root: classes.usetableInput,
            input: classes.inputBaseInput,
          }}
          varient="outlined"
          value={msgVal}
          onKeyDown={(e) => sendMsgHandler(e)}
          onChange={(e) => setMsgVal(e.target.value)}
          endAdornment={
            <img
              alt=""
              onClick={sendClickhandler}
              src={sendMessageIcon}
              style={{ cursor: "pointer" }}
            />
          }
        />
      </Box>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.content}>
            {onlineUsersList()}
            {innerChat()}
            {footer()}
          </div>
        </div>
      </div>
    </>
  );
}
